import {
  CognitoIdentityClient,
  GetOpenIdTokenCommand,
} from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';

const getToken = async () => {
  const awsRegion = process.env.AWS_REGION;
  const identityPoolId = process.env.COGNITO_IDENTITY_POOL_ID;
  const client = new CognitoIdentityClient({ region: awsRegion });

  const getIdentity = await fromCognitoIdentityPool({
    identityPoolId,
    client,
  });
  const identity = await getIdentity();
  const command = new GetOpenIdTokenCommand({
    IdentityId: identity.identityId,
  });
  const response = await client.send(command);

  return response.Token;
};

export default getToken;
