export const VARIANT_ATTRIBUTE_NAME_COLOR = 'color';
export const VARIANT_ATTRIBUTE_NAME_COLOR_FOR_BEDFRAME = 'bedframe-color';
export const VARIANT_ATTRIBUTE_NAMES_COLOR = [
  VARIANT_ATTRIBUTE_NAME_COLOR,
  VARIANT_ATTRIBUTE_NAME_COLOR_FOR_BEDFRAME,
];
export const VARIANT_ATTRIBUTE_NAME_COLOR_CODE = 'color-code';
export const VARIANT_ATTRIBUTE_NAME_PILLOW_SIZE = 'pillow-size';
export const VARIANT_ATTRIBUTE_NAME_SIZE = 'size';
export const NON_DETERMINISTIC_ATTRIBUTES = ['whatIncluded', 'isOutOfStock'];
export const VARIANT_ATTRIBUTE_OUT_OF_STOCK = 'isOutOfStock';
export const FEEL_ATTRIBUTE_NAME = 'feel';
export const DEPTH_ATTRIBUTE_NAME = 'depth';
export const KID_SHEETS_COLOR_KEY = ['pink', 'blue'];
export const SOFTSTRETCH_SHEETS_KEY = '06218d33-26e3-4b95-8f0a-224381463419';
export const ATTRIBUTE_DESCRIPTION_COOKIE = 'attribute_description_test_pdp_selectors';
export const KID_SHEETS_KEY = '0c860679-4eaa-4984-bd03-f417396aab44';
