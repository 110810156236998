import { createSlice, createAction } from '@reduxjs/toolkit';
import { getActiveCart } from '../cart';
import { isSameAddressWithName } from '../../utils';

export const billingAddressOptions = {
  same: 'same-as-shipping',
  different: 'different-as-shipping',
};

const billingAddressInitialState = {
  email: '',
  phone: '',
  firstName: '',
  lastName: '',
  streetAddress: '',
  additionalStreetInfo: '',
  postalCode: '',
  city: '',
  country: 'US',
  state: '',
};

const getInitialBillingAddressOptionFromCart = (cart) => {
  if (
    !cart ||
    !cart.billingAddress ||
    isSameAddressWithName(cart.billingAddress, cart.shippingAddress)
  ) {
    return billingAddressOptions.same;
  }
  return billingAddressOptions.different;
};

export const setBillingAddressOption = createAction(
  'billingAddress/setBillingAddressOption',
);
export const setBillingAddressDraft = createAction(
  'billingAddress/setBillingAddressDraft',
);
export const setIsValidate = createAction('billingAddress/setIsValidate');
export const setIsValid = createAction('billingAddress/setIsValid');

export const billingAddressSlice = createSlice({
  name: 'billingAddress',
  initialState: {
    billingAddressOption: billingAddressOptions.same,
    billingAddressDraft: billingAddressInitialState,
    isValidate: false,
    isValid: false,
  },
  reducers: {
    setBillingAddressOption: (state, action) => {
      const billingAddressDraft =
        action.payload === billingAddressOptions.same
          ? state.billingAddressDraft
          : {
            ...billingAddressInitialState,
            email: state.billingAddressDraft.email,
            phone: state.billingAddressDraft.phone,
          };
      return {
        ...state,
        billingAddressDraft,
        billingAddressOption: action.payload,
      };
    },
    setBillingAddressDraft: (state, action) => ({
      ...state,
      billingAddressDraft: action.payload,
    }),
    setIsValidate: (state, action) => ({
      ...state,
      isValidate: action.payload,
    }),
    setIsValid: (state, action) => ({
      ...state,
      isValid: action.payload,
    }),
  },
  extraReducers: {
    [getActiveCart.fulfilled]: (state, actions) => {
      const billingAddressDraft =
        actions.payload?.billingAddress || billingAddressInitialState;

      return {
        ...state,
        billingAddressOption: getInitialBillingAddressOptionFromCart(
          actions.payload,
        ),
        billingAddressDraft: {
          ...billingAddressDraft,
          streetAddress:
            billingAddressDraft.streetAddress || billingAddressDraft.streetName,
        },
      };
    },
  },
});

export default billingAddressSlice.reducer;
