import cartService from './cart';
import cartDraftService from './draftCart';

const getServiceType =
  (isDraft) => (isDraft ? cartDraftService : cartService);

const getServiceName =
  (isDraft) => (isDraft ? 'cartDraftService' : 'cartService');

const getMethodService =
  (isDraft, methodName) => getServiceType(isDraft)[methodName];

const callToMethod = ({
  cartId,
  isDraft,
  methodName,
  paramsObject,
}) => {
  const method = getMethodService(isDraft, methodName);

  if (!method) {
    throw new TypeError(
      // eslint-disable-next-line max-len
      `Invalid param sent: method "${methodName}" does not exist in "${getServiceName(isDraft)}"`,
    );
  }

  if (!isDraft) {
    return method(paramsObject);
  }

  return method(cartId, paramsObject);
};

const methodsMap = Object.freeze({
  setBillingAddress: 'setBillingAddress',
  setShippingAddress: 'setShippingAddress',
  getShippingMethods: 'getShippingMethods',
  setShippingMethods: 'setShippingMethods',
  addGiftCard: 'addGiftCard',
  removeGiftCard: 'removeGiftCard',
});

const cartMasterService = {
  callToMethod,
  methodsMap,
};

export default cartMasterService;
