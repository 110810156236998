import ordersMasterService from '../orders/ordersMasterService';

const PROVIDER = 'AMAZON_PAY';

const getAmazonPayButtonSettings = (cartId, isDraft) =>
  ordersMasterService.callToMethod(
    {
      cartId,
      isDraft,
      methodName: ordersMasterService.methodsMap.authorizeSession,
      paramsObject: { provider: PROVIDER },
    },
  );

const getCheckoutSession = async ({ checkoutSessionId }) => {
  const response = await fetch(
    // eslint-disable-next-line
    `${process.env.BASE_API_URL}/payment-hub/checkout-session?provider=${PROVIDER}&checkoutSessionId=${checkoutSessionId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.ok) {
    return response.json();
  }
  throw new Error('Something wrong happened', response.status);
};

const paymentIntent = ({ cartId, isDraft, checkoutSessionId }) =>
  ordersMasterService.callToMethod(
    {
      cartId,
      isDraft,
      methodName: ordersMasterService.methodsMap.updateCheckoutSession,
      paramsObject: {
        provider: PROVIDER,
        checkoutSessionId,
      },
    },
  );

const amazonPayService = {
  getAmazonPayButtonSettings,
  getCheckoutSession,
  paymentIntent,
};

export const AMAZON_PAY_STATE_CANCELED = 'Canceled';
export const AMAZON_PAY_STATE_PROCESSED = 'Processed';
export default amazonPayService;
