import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import {
  mapResponseToAvailableShippingMethod,
  mapAvailableShippingMethodToShippingMethod,
  parseShippingMethodsToPayload,
  isValidCartShippingMethods,
} from './shippingMethods.utils';
import cartMasterService from '../../services/cart/cartMasterService';
import { setCart } from '../cart';

export const getAvailableShippingMethods = createAsyncThunk(
  'shippingMethods/getAvailableShippingMethods',
  async (
    { locale, overwritePaidInHomeSetupShipping, customInHomeSetupPricing },
    { getState, rejectWithValue },
  ) => {
    const { cart } = getState();
    let shippingMethods;
    try {
      shippingMethods = await cartMasterService.callToMethod({
        cartId: cart.id,
        isDraft: cart.isDraft,
        methodName: cartMasterService.methodsMap.getShippingMethods,
        paramsObject: {
          overwritePaidInHomeSetupShipping,
          customInHomeSetupPricing,
        },
      });
    } catch (error) {
      return rejectWithValue({
        errorCode: error.apiErrors?.[0]?.code,
      });
    }

    const availableShippingMethods = shippingMethods.map((shippingMethod) =>
      mapResponseToAvailableShippingMethod(
        shippingMethod,
        cart.lineItems,
        locale,
      ),
    );

    const payload = {
      availableShippingMethods,
    };

    return payload;
  },
);

export const getShippingMethods = createAsyncThunk(
  'shippingMethods/get',
  async (
    {
      locale,
      forceDefaultShippingMethods,
      overwritePaidInHomeSetupShipping,
      customInHomeSetupPricing,
    },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { cart } = getState();
    let shippingMethods;
    try {
      shippingMethods = await cartMasterService.callToMethod({
        cartId: cart.id,
        isDraft: cart.isDraft,
        methodName: cartMasterService.methodsMap.getShippingMethods,
        paramsObject: {
          overwritePaidInHomeSetupShipping,
          customInHomeSetupPricing,
        },
      });
    } catch (error) {
      return rejectWithValue({
        errorCode: error.apiErrors?.[0]?.code,
      });
    }

    const availableShippingMethods = shippingMethods.map((shippingMethod) =>
      mapResponseToAvailableShippingMethod(
        shippingMethod,
        cart.lineItems,
        locale,
      ),
    );
    const isValidCart = isValidCartShippingMethods(cart);

    const payload = {
      availableShippingMethods,
      shippingMethods: availableShippingMethods.map((availableShippingMethod) =>
        mapAvailableShippingMethodToShippingMethod(
          availableShippingMethod,
          forceDefaultShippingMethods ? false : isValidCart && cart,
          locale,
        ),
      ),
    };

    if (!isValidCart || forceDefaultShippingMethods) {
      dispatch({
        type: 'setShippingMethodStatus',
        status: 'loading',
      });

      try {
        const newCart = await cartMasterService.callToMethod({
          cartId: cart.id,
          isDraft: cart.isDraft,
          methodName: cartMasterService.methodsMap.setShippingMethods,
          paramsObject: {
            selectedShippingMethods: parseShippingMethodsToPayload(
              payload.shippingMethods,
            ),
          },
        });
        dispatch(setCart(newCart));
        // eslint-disable-next-line no-empty
      } catch (e) {}

      dispatch({
        type: 'setShippingMethodStatus',
        status: 'success',
      });
    }

    return payload;
  },
);

export const setShippingMethodsForAllProductsInCart = createAsyncThunk(
  'shippingMethods/setShippingMethodsForAllProductsInCart',
  async (
    {
      shippingMethods,
      overwritePaidInHomeSetupShipping,
      customInHomeSetupPricing,
    },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { id, isDraft } = getState().cart;

    const newCart = await cartMasterService.callToMethod({
      cartId: id,
      isDraft,
      methodName: cartMasterService.methodsMap.setShippingMethods,
      paramsObject: {
        selectedShippingMethods: parseShippingMethodsToPayload(shippingMethods),
        overwritePaidInHomeSetupShipping,
        customInHomeSetupPricing,
      },
    });

    dispatch({
      type: 'setShippingMethodStatus',
      status: 'loading',
    });

    try {
      dispatch({
        type: 'shippingMethods/setShippingMethods',
        payload: shippingMethods,
      });
      dispatch({
        type: 'setShippingMethodStatus',
        status: 'success',
      });
      return dispatch(setCart(newCart));
    } catch (error) {
      dispatch({
        type: 'setShippingMethodStatus',
        status: 'failed',
      });
      return rejectWithValue({
        // TODO: fix error handling
        errorCode: error,
      });
    }
  },
);

export const setShowChangeInfoModal = createAction(
  'shippingMethods/setShowChangeInfoModal',
);

export const setNeedToRecheckSelectedShippingMethods = createAction(
  'shippingMethods/setNeedToRecheckSelectedShippingMethods',
);

export const setTealiumShippingMethodStatus = createAction(
  'shippingMethods/setTealiumShippingMethodStatus',
);

export const clearAvailableShippingMethods = createAction(
  'shippingMethods/clearAvailableShippingMethods',
);

export const setSelectedShippingMethods = createAction(
  'shippingMethods/setSelectedShippingMethods',
);

export const shippingMethodsSlice = createSlice({
  name: 'shippingMethods',
  initialState: {
    availableShippingMethods: [],
    needToRecheckSelectedShippingMethods: false,
    shippingMethods: [],
    status: 'idle',
    setShippingMethodStatus: 'succeeded',
    showChangeInfoModal: false,
    tealiumShippingMethodStatus: false,
    selectedShippingMethods: [],
  },
  reducers: {
    setShippingMethods: (state, action) => ({
      ...state,
      shippingMethods: action.payload,
    }),
    clearAvailableShippingMethods: (state) => ({
      ...state,
      availableShippingMethods: [],
    }),
    setNeedToRecheckSelectedShippingMethods: (state, action) => ({
      ...state,
      needToRecheckSelectedShippingMethods: action.payload,
    }),
    setShowChangeInfoModal: (state, action) => ({
      ...state,
      showChangeInfoModal: action.payload,
    }),
    setTealiumShippingMethodStatus: (state, action) => ({
      ...state,
      tealiumShippingMethodStatus: action.payload,
    }),
    setSelectedShippingMethods: (state, action) => ({
      ...state,
      selectedShippingMethods: action.payload,
    }),
  },
  extraReducers: {
    [getAvailableShippingMethods.pending]: (state) => ({
      ...state,
      status: 'loading',
      errorCode: undefined,
    }),
    [getAvailableShippingMethods.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      status: 'succeeded',
      errorCode: undefined,
    }),
    [getAvailableShippingMethods.rejected]: (state, action) => ({
      ...state,
      status: 'failed',
      availableShippingMethods: [],
      errorCode: action.payload?.errorCode,
    }),
    [getShippingMethods.pending]: (state) => ({
      ...state,
      status: 'loading',
      errorCode: undefined,
    }),
    [getShippingMethods.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      status: 'succeeded',
      errorCode: undefined,
    }),
    [getShippingMethods.rejected]: (state, action) => ({
      ...state,
      setShippingMethodStatus: 'failed',
      status: 'failed',
      errorCode: action.payload?.errorCode,
    }),
    [setShippingMethodsForAllProductsInCart.pending]: (state) => ({
      ...state,
      setShippingMethodStatus: 'loading',
    }),
    [setShippingMethodsForAllProductsInCart.fulfilled]: (state) => ({
      ...state,
      setShippingMethodStatus: 'succeeded',
    }),
    [setShippingMethodsForAllProductsInCart.rejected]: (state) => ({
      ...state,
      setShippingMethodStatus: 'failed',
    }),
    setShippingMethodStatus: (state, payload) => ({
      ...state,
      setShippingMethodStatus: payload.status,
    }),
  },
});

export const { setShippingMethodKey, setNeedsRemoval } =
  shippingMethodsSlice.actions;
export default shippingMethodsSlice.reducer;
