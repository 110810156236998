import { getAttributeValue } from '../../utils/variants';

export const isValidCartShippingMethods = ({ lineItems, customLineItems }) => {
  const lineItemsIds = lineItems.map((lineItem) => lineItem.id);
  const shippingMethodsIds = customLineItems
    .flatMap((customLineItem) => customLineItem.custom?.fields.lineItemsIds)
    .filter(Boolean);

  return (
    lineItemsIds.every((id) => shippingMethodsIds.includes(id)) &&
    shippingMethodsIds.every((id) => lineItemsIds.includes(id))
  );
};

const getSelectedShippingMethods = (cart, skus, locale) =>
  (!cart
    ? {}
    : skus.reduce((acc, sku) => {
      const lineItem = cart.lineItems.find((li) => li.variant.sku === sku);
      const shippingMethodCustomField = cart.customLineItems.find(
        (customLineItem) =>
          customLineItem.custom?.fields?.netsuite_shipping_name &&
            customLineItem.custom?.fields?.lineItemsIds?.includes(lineItem.id),
      );
      return {
        key: shippingMethodCustomField?.custom.fields.netsuite_shipping_name,
        needsRemoval:
            shippingMethodCustomField?.custom.fields.mattress_removal,
        cost: shippingMethodCustomField?.money.centAmount,
        name: shippingMethodCustomField?.name[locale],
      };
    }, {}));

export const getSelectedShippingMethodsForAvailables = ({
  availableShippingMethods,
  cart,
}) =>
  availableShippingMethods?.map((availableShippingMethod) => ({
    ...availableShippingMethod,
    groups: availableShippingMethod.groups?.map((group) => {
      const relatedLineItems = cart?.lineItems?.filter((currentLineItem) =>
        group.products?.find(
          (productForGroup) =>
            productForGroup.sku === currentLineItem?.variant?.sku,
        ),
      );
      const relatedCustomLineItem = cart?.customLineItems?.find(
        (currentCustomLineItem) =>
          (relatedLineItems?.length > 0
            ? currentCustomLineItem.custom?.fields.netsuite_shipping_name &&
              relatedLineItems?.every((relatedLineItem) =>
                currentCustomLineItem.custom?.fields?.lineItemsIds?.find(
                  (currentLineItemId) =>
                    currentLineItemId === relatedLineItem.id,
                ),
              )
            : undefined),
      );
      return {
        ...group,
        selectedShippingMethod: relatedCustomLineItem ? {
          ...group.shippingMethods?.find(
            (currentShippingMethod) =>
              currentShippingMethod.key ===
                relatedCustomLineItem.custom?.fields.netsuite_shipping_name),
          price: relatedCustomLineItem.money,
          needsRemoval:
            typeof relatedCustomLineItem.custom?.fields.mattress_removal ===
            'boolean'
              ? relatedCustomLineItem.custom?.fields.mattress_removal
              : false,
        } : undefined,
      };
    }),
  }));

export const getGroupedSelectedShippingMethodsWithProducts = ({
  cart,
  locale,
}) => ({
  shippingMethods:
    cart?.customLineItems
      ?.filter(
        (customLineItem) =>
          (customLineItem?.custom?.fields?.netsuite_shipping_name
            && customLineItem?.custom?.fields?.lineItemsIds.length > 0),
      )
      .map((shippingMethodLineItem) => ({
        key: shippingMethodLineItem.custom?.fields?.netsuite_shipping_name,
        name: shippingMethodLineItem.name?.[locale],
        needsRemoval: shippingMethodLineItem.custom?.fields?.mattress_removal,
        products: cart?.lineItems
          ?.filter((lineItem) =>
            shippingMethodLineItem.custom?.fields?.lineItemsIds.includes(
              lineItem.id,
            ),
          )
          .map((currentLineItem) => ({
            name: currentLineItem.name?.[locale],
            productId: currentLineItem.productId,
            productKey: currentLineItem.productKey,
            quantity: currentLineItem.quantity,
            variant: currentLineItem.variant,
          })),
      })) ?? [],
});

export const getFreeOrMinorPriceShippingMethodFromAvailable = ({
  availableShippingMethod,
}) => ({
  parentKey: availableShippingMethod.key,
  groups: availableShippingMethod.groups.map(
    ({ products, shippingMethods }) => {
      const skus = products.map(({ sku }) => sku);
      const {
        key, needsRemoval, cost, name,
      } = shippingMethods.reduce(
        (acc, curr) => {
          if (
            acc.price?.centAmount === undefined ||
            curr.price?.centAmount < acc.price?.centAmount
          ) {
            return { ...curr, cost: curr.price?.centAmount };
          }
          return acc;
        },
        { price: { centAmount: undefined } },
      );

      return {
        key,
        name,
        cost,
        needsRemoval: typeof needsRemoval === 'boolean' ? needsRemoval : false,
        skus,
      };
    },
  ),
});

export const mapAvailableShippingMethodToShippingMethod = (
  availableShippingMethod,
  cart,
  locale,
) => ({
  parentKey: availableShippingMethod.key,
  groups: availableShippingMethod.groups.map(
    ({ products, shippingMethods }) => {
      const skus = products.map(({ sku }) => sku);
      const {
        key, needsRemoval, cost, name,
      } = getSelectedShippingMethods(
        cart,
        skus,
        locale,
      );

      return {
        key: key || shippingMethods[0].key,
        name: name || shippingMethods[0].name,
        cost: cost || shippingMethods[0].price.centAmount,
        needsRemoval: typeof needsRemoval === 'boolean' ? needsRemoval : false,
        skus,
      };
    },
  ),
});

export const mapResponseToAvailableShippingMethod = (
  { key, name, groups },
  lineItems,
  locale,
) => ({
  key,
  name,
  groups: groups.map(({ products, shippingMethods }, i) => ({
    id: i,
    products: lineItems
      .filter(({ variant }) => products.includes(variant.sku))
      .map(({ name: n, variant }) => ({
        name: n[locale],
        title: getAttributeValue(variant.attributes, 'title'),
        image: {
          url: variant.images[0]?.url,
          label: variant.images[0]?.label || n[locale],
        },
        sku: variant.sku,
      })),
    shippingMethods: shippingMethods.sort(
      (a, b) => a.price.centAmount - b.price.centAmount,
    ),
  })),
});

export const changeValueShippingMethods = (
  shippingMethods,
  sm,
  parentKey,
  groupId,
) =>
  shippingMethods.map((shippingMethod) =>
    (shippingMethod.parentKey === parentKey
      ? {
        parentKey,
        groups: shippingMethod.groups.map((group, i) =>
          (i === groupId
            ? {
              ...sm,
              needsRemoval: sm.needsRemoval || false,
              skus: group.skus,
            }
            : group),
        ),
      }
      : shippingMethod),
  );

export const parseShippingMethodsToPayload = (shippingMethods) => {
  let payloadShippingMethods = [];

  shippingMethods.forEach(({ groups }) =>
    groups.forEach((group) => {
      const foundShippingMethod = payloadShippingMethods.find(
        ({ key }) => key === group.key,
      );
      if (!foundShippingMethod) payloadShippingMethods.push(group);
      else {
        payloadShippingMethods = payloadShippingMethods.map(
          (payloadShippingMethod) =>
            (payloadShippingMethod.key === group.key
              ? {
                ...payloadShippingMethod,
                skus: [...payloadShippingMethod.skus, ...group.skus],
              }
              : payloadShippingMethod),
        );
      }
    }),
  );
  return {
    shippingMethods: payloadShippingMethods,
  };
};
