import {
  calculatetShippingTotal,
  getProductAttributes,
  calculateSubtotal,
} from '../../../utils';
import {
  WARRANTY_CUSTOM_LINE_ITEM_TYPE,
  ITEM_LIST_IDS_TEALIUM,
  TEALIUM_ADDED_LOCATION_COOKIE,
  TEALIUM_IMPACT_READIUS_COOKIE,
} from '../../../utils/common/constants';
import tealiumStorageService from '../../../services/tealiumStorage';

const SUBSCRIBED_TEALIUM_FULFILLED_EVENTS = [
  'order/getOrder/fulfilled',
  'cart/addLineItems/fulfilled',
  'cart/removeLineItem/fulfilled',
  'cart/addWarrantyPlanToLineItem/fulfilled',
  'cart/getCartAOVs/fulfilled',
  'accounts/setCustomerId/fulfilled',
  'cart/changeLineItemQuantity/fulfilled',
  'cart/getActiveCart/fulfilled',
];

const SUBSCRIBED_TEALIUM_CART_PENDING_EVENTS = [
  'cart/removeLineItem/pending',
  'cart/changeLineItemQuantity/pending',
  'cart/addWarrantyPlanToLineItem/pending',
];

const tealiumEventsFired = {
  cartAOV: false,
  cart: false
};

const tealiumUpdatedEventData = ({
  actionType,
  args,
  cartDataBeforeLineitemChange,
  tealiumFiredForWarranty,
  cart,
  order,
  aov,
}) => {
  let utagData = {
    price: [],
    originalPrice: [],
    productDiscount: [],
    parentCategory: [],
    subCategory: [],
    productName: [],
    productId: [],
    productBrand: [],
    productColor: [],
    productSize: [],
    productVariant: [],
    productQuantity: [],
    productHeight: [],
    productVariantExtraAttributes: [],
    customerId: null,
    customeFirstName: null,
    customerLastName: null,
    customerEmail: null,
    customerPhone: null,
    streetName: null,
    additionalStreetInfo: null,
    customerCity: null,
    customerState: null,
    customerCountry: null,
    customerPostalCode: null,
    itemListId: [],
    itemListName: [],
    itemAddedLocation: [],
    producItemListId: [],
    productItemListName: [],
  };
  let tealiumFiredForWarrantyFlag = false;
  switch (actionType) {
    case 'order/getOrder/fulfilled': {
      const taxes = order?.taxedPrice
        ? order.taxedPrice.totalGross.centAmount -
          order.taxedPrice.totalNet.centAmount
        : null;

      const { customLineItems, lineItems } = order;


      const hasAnyMattress = lineItems.some(
        (item) => item?.category?.[1]?.name === 'Mattress',
      );
      const shippingMethodKeys = customLineItems?.filter(
        (customLineItem) =>
          customLineItem.custom?.fields?.netsuite_shipping_name,
      );
      let itemsShippingMap = [];
      if (shippingMethodKeys.length) {
        itemsShippingMap = [
          ...itemsShippingMap,
          ...shippingMethodKeys.map(
            (shipMethod) => shipMethod?.name?.['en-US'],
          ),
        ];
      }
      const shippingMethodsSelected = itemsShippingMap.join(' , ');

      let shippingTotal = 0;
      if (customLineItems) {
        shippingTotal = calculatetShippingTotal({ customLineItems });
      }
      let promoCodes = null;
      lineItems.forEach((item) => {
        // fetch cart item added location from localstorage
        const addedLocation = JSON.parse(
          localStorage.getItem(TEALIUM_ADDED_LOCATION_COOKIE),
        );
        const lineItemLocation = addedLocation.find(
          (location) => location.lineItemId === item.variant.sku,
        );
        const discountJson = item?.custom?.fields?.discounts_json;
        const itemDiscount =
          discountJson &&
          JSON.parse(discountJson)?.reduce(
            (totalLine, discount) => totalLine + discount.cent_amount,
            0,
          );
        const couponCodes =
          discountJson &&
          JSON.parse(discountJson)?.reduce(
            (allCoupons, discount) =>
              discount.coupon_code
                ? [...allCoupons, discount.coupon_code]
                : allCoupons,
            [],
          );
        promoCodes = couponCodes.length ? couponCodes : null;

        const {
          parentCategory,
          subCategory,
          variantColor,
          variantSize,
          productVariantExtraAttributes,
        } = getProductAttributes(item);

        utagData = {
          price: [...utagData.price, item?.price?.value?.centAmount / 100],
          originalPrice: [
            ...utagData.originalPrice,
            (item?.price?.value?.centAmount + itemDiscount) / 100,
          ],
          parentCategory: [...utagData.parentCategory, parentCategory],
          subCategory: [...utagData.subCategory, subCategory],
          productName: [...utagData.productName, item.name['en-US']],
          productId: [...utagData.productId, item.variant.sku],
          productBrand: [...utagData.productBrand, 'Purple'],
          productColor: [...utagData.productColor, variantColor],
          productSize: [...utagData.productSize, variantSize],
          productVariant: [
            ...utagData.productVariant,
            productVariantExtraAttributes,
          ],
          productQuantity: [...utagData.productQuantity, item.quantity],
          productDiscount: [...utagData.productDiscount, itemDiscount],
          itemListId: [...utagData.itemListId, lineItemLocation?.item_list_id],
          itemListName: [
            ...utagData.itemListName,
            lineItemLocation?.item_list_id
              ? lineItemLocation.item_list_id
                  .replace(/_/g, ' ')
                  .replace(/\b\w/g, (char) => char.toUpperCase())
              : null,
          ],
          itemAddedLocation: [
            ...utagData.itemAddedLocation,
            lineItemLocation.item_added_location,
          ],
          productItemListId: [...utagData.itemListId, lineItemLocation?.item_list_id],
          productItemListName: [
            ...utagData.itemListName,
            lineItemLocation?.item_list_id
              ? lineItemLocation.item_list_id
                  .replace(/_/g, ' ')
                  .replace(/\b\w/g, (char) => char.toUpperCase())
              : null,
          ],
        };
      });

      utagData = {
        ...utagData,
        customerId: order.customerId,
        customeFirstName: order.shippingAddress.firstName,
        customerLastName: order.shippingAddress.lastName,
        customerEmail: order.customerEmail,
        customerPhone: order.shippingAddress.phone,
        streetName: order.shippingAddress.streetName,
        additionalStreetInfo: order.shippingAddress.additionalStreetInfo,
        customerCity: order.shippingAddress.city,
        customerState: order.shippingAddress.state,
        customerCountry: order.shippingAddress.country,
        customerPostalCode: order.shippingAddress.postalCode,
      };

      const selectedWarrantyPlans = customLineItems.filter(
        (customLineItem) =>
          customLineItem.custom?.fields?.type ===
          WARRANTY_CUSTOM_LINE_ITEM_TYPE,
      );
      if (selectedWarrantyPlans?.length) {
        selectedWarrantyPlans.forEach((warranty) => {
          const {
            custom: { fields: selectedWarrantyPlan },
          } = warranty;
          const { warrantyPlanId, warrantyPrice, warrantyTerm, lineItemsIds } =
            selectedWarrantyPlan;
          const warrantyDurationInYears = warrantyTerm / 12;

          utagData = {
            ...utagData,
            price: [...utagData.price, warrantyPrice / 100],
            originalPrice: [...utagData.originalPrice, warrantyPrice / 100],
            parentCategory: [...utagData.parentCategory, 'Extend Protection'],
            subCategory: [
              ...utagData.subCategory,
              `${
                lineItems.find((item) => item.id === lineItemsIds[0])?.name[
                  'en-US'
                ]
              } Protection`,
            ],
            productName: [
              ...utagData.productName,
              `Extend Protection ${warrantyDurationInYears}Y`,
            ],
            productId: [...utagData.productId, warrantyPlanId],
            productBrand: [...utagData.productBrand, 'Extend'],
            productColor: [...utagData.productColor, null],
            productSize: [...utagData.productSize, null],
            productVariant: [...utagData.productVariant, null],
            productQuantity: [...utagData.productQuantity, warranty.quantity],
            productDiscount: [...utagData.productDiscount, 0],
            productItemListId: [
              ...utagData.itemListId,
              'Cart',
            ],
            productItemListName: [
              ...utagData.itemListName,
              'Cart',
            ],
          };
        });
      }
      window.utag?.view({
        event_name: 'Order confirmation',
        page_name: 'Order Confirmation',
        page_title: document.title,
        page_type: 'Order Confirmation',
        click_id: localStorage.getItem(TEALIUM_IMPACT_READIUS_COOKIE),
        order_id: order.orderNumber,
        order_tax: taxes / 100,
        order_shipping_total: shippingTotal / 100,
        order_shipping_method: shippingMethodsSelected,
        order_sub_total: order.totalPrice.centAmount / 100,
        order_total: order.taxedPrice.totalGross.centAmount / 100,
        promo_code: promoCodes,
        mattress_purchase: hasAnyMattress,
        item_list_id: utagData.itemListId,
        item_list_name: utagData.itemListName,
        item_added_location: utagData.itemAddedLocation,
        product_item_list_id: utagData.productItemListId,
        product_item_list_name: utagData.productItemListName,
        product_id: utagData.productId,
        product_name: utagData.productName,
        product_brand: utagData.productBrand,
        product_category: utagData.parentCategory,
        product_sub_category: utagData.subCategory,
        product_quantity: utagData.productQuantity,
        product_price: utagData.price,
        product_original_price: utagData.originalPrice,
        product_color: utagData.productColor,
        product_size: utagData.productSize,
        product_variant: utagData.productVariant,
        product_discount_value: utagData.productDiscount,
        ...(utagData.customerId ? { customer_id: utagData.customerId } : {}),
        customer_first_name: utagData.customeFirstName,
        customer_last_name: utagData.customerLastName,
        customer_email: utagData.customerEmail,
        customer_phone: utagData.customerPhone,
        customer_address_1: utagData.streetName,
        customer_address_2: utagData.additionalStreetInfo,
        customer_city: utagData.customerCity,
        customer_state: utagData.customerState,
        customer_country: utagData.customerCountry,
        customer_postal_code: utagData.customerPostalCode,
      });
      localStorage.removeItem(TEALIUM_IMPACT_READIUS_COOKIE);
      break;
    }
    case 'cart/addLineItems/fulfilled': {
      const lineItems = args?.lineItems;

      const lineItmLength = lineItems.length;
      lineItems.forEach((lineItm, lineItmIndex) => {
        const {
          sku,
          warrantyPlanId,
          quantity,
          warrantyPrice,
          item_list_id: itemsListId,
        } = lineItm;
        const foundItem = cart?.lineItemId
          ? lineItems.find((lineItem) => lineItem?.id === cart.lineItemId)
          : cart?.lineItems.find((lineItem) => lineItem?.variant.sku === sku);
        const discountJson = foundItem?.custom?.fields?.discounts_json;
        const itemDiscount =
          discountJson &&
          JSON.parse(discountJson)?.reduce(
            (totalLine, discount) => totalLine + discount.cent_amount,
            0,
          );
        if (foundItem) {
          tealiumStorageService.setCookieForTealiumLineItemAddedLocation({
            lineItemId: foundItem.variant.sku,
            itemsListId,
            itemAddedLocation: document.location.pathname,
          });
          const {
            parentCategory,
            subCategory,
            variantColor,
            variantSize,
            productVariantExtraAttributes,
          } = getProductAttributes(foundItem);

          const itemName = foundItem.name['en-US'];

          utagData = {
            price: [
              ...utagData.price,
              foundItem?.price?.value?.centAmount / 100,
            ],
            originalPrice: [
              ...utagData.originalPrice,
              (foundItem?.price?.value?.centAmount + itemDiscount) / 100,
            ],
            parentCategory: [...utagData.parentCategory, parentCategory],
            subCategory: [...utagData.subCategory, subCategory],
            productName: [...utagData.productName, itemName],
            productId: [...utagData.productId, foundItem.variant.sku],
            productBrand: [...utagData.productBrand, 'Purple'],
            productColor: [...utagData.productColor, variantColor],
            productSize: [...utagData.productSize, variantSize],
            productVariant: [
              ...utagData.productVariant,
              productVariantExtraAttributes,
            ],
            productQuantity: [...utagData.productQuantity, quantity || 1],
            productDiscount: [...utagData.productDiscount, itemDiscount],
            producItemListId: [
              ...utagData.producItemListId,
              lineItm?.item_list_id,
            ],
            productItemListName: [
              ...utagData.productItemListName,
              lineItm?.item_list_id
                ? lineItm?.item_list_id
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, (char) => char.toUpperCase())
                : null,
            ],
          };
        }

        if (warrantyPlanId) {
          const warrantyDuration = warrantyPlanId.split('-')[2];
          utagData = {
            price: [...utagData.price, warrantyPrice / 100],
            originalPrice: [...utagData.originalPrice, warrantyPrice / 100],
            parentCategory: [...utagData.parentCategory, 'Extend Protection'],
            subCategory: [
              ...utagData.subCategory,
              `${Object.values(foundItem.name)[0]} Protection`,
            ],
            productName: [
              ...utagData.productName,
              `Extend Protection ${warrantyDuration}Y`,
            ],
            productId: [...utagData.productId, warrantyPlanId],
            productBrand: [...utagData.productBrand, 'Extend'],
            productColor: [...utagData.productColor, null],
            productSize: [...utagData.productSize, null],
            productVariant: [...utagData.productVariant, null],
            productQuantity: [...utagData.productQuantity, 1],
            productDiscount: [...utagData.productDiscount, 0],
            producItemListId: [
              ...utagData.producItemListId,
              'cart',
            ],
            productItemListName: [
              ...utagData.productItemListName,
              'cart',
            ],
          };
        }

        if (lineItmLength === lineItmIndex + 1) {

          window.utag?.link({
            event_name: 'Add to cart',
            item_list_id: lineItems[0]?.item_list_id || null,
            item_list_name: lineItems[0]?.item_list_id
              ? lineItems[0]?.item_list_id
                  .replace(/_/g, ' ')
                  .replace(/\b\w/g, (char) => char.toUpperCase())
              : null,
            product_item_list_id: utagData.producItemListId,
            product_item_list_name: utagData.productItemListName,
            product_id: utagData.productId,
            product_name: utagData.productName,
            product_brand: utagData.productBrand,
            product_category: utagData.parentCategory,
            product_sub_category: utagData.subCategory,
            product_quantity: utagData.productQuantity,
            product_price: utagData.price,
            product_original_price: utagData.originalPrice,
            product_color: utagData.productColor,
            product_size: utagData.productSize,
            product_variant: utagData.productVariant,
            product_discount_value: utagData.productDiscount,
          });
        }
      });
      break;
    }
    case 'cart/changeLineItemQuantity/fulfilled': {
      const prevQuantity = cartDataBeforeLineitemChange?.lineItems
        ?.filter(item => item.id === args.lineItemId
      )?.[0].quantity;

      const updatedQuantity = args.quantity;
      
      let lineItems; let customLineItems; let lineItemUpdated;

      if (prevQuantity < updatedQuantity) {
        ({ lineItems, customLineItems } = cart);
        const { sku } = args?.lineItems?.[0] || args;
        [lineItemUpdated] = cart?.lineItems
          .filter((lineItem) => lineItem?.variant.sku === sku)
          .slice(-1);
      } else {
        ({ lineItems, customLineItems } = cartDataBeforeLineitemChange);
        [lineItemUpdated] = lineItems.filter(
          (lineItem) => lineItem.id === args.lineItemId,
        );
      }

      const warrantyLineItems =
        customLineItems.filter(
          (customLineItem) =>
            customLineItem.custom?.fields?.type ===
              WARRANTY_CUSTOM_LINE_ITEM_TYPE &&
            customLineItem.custom?.fields?.lineItemsIds.includes(
              args.lineItemId,
            ),
        );

      const [warrantyLineItemUpdated] = warrantyLineItems.length
        ? warrantyLineItems
        : [];
    

      const discountJson = lineItemUpdated?.custom?.fields?.discounts_json;
      const itemDiscount =
        discountJson &&
        JSON.parse(discountJson)?.reduce(
          (totalLine, discount) => totalLine + discount.cent_amount,
          0,
        );
      const itemListId =
        prevQuantity < updatedQuantity
          ? ITEM_LIST_IDS_TEALIUM.cartQuantityIncrease
          : ITEM_LIST_IDS_TEALIUM.cartQuantityDecrease;
      const itemListName =
        prevQuantity < updatedQuantity
          ? ITEM_LIST_IDS_TEALIUM.cartQuantityIncrease
              .replace(/_/g, ' ')
              .replace(/\b\w/g, (char) => char.toUpperCase())
          : ITEM_LIST_IDS_TEALIUM.cartQuantityDecrease
              .replace(/_/g, ' ')
              .replace(/\b\w/g, (char) => char.toUpperCase());

      if (lineItemUpdated) {
        const {
          parentCategory,
          subCategory,
          variantColor,
          variantSize,
          productVariantExtraAttributes,
        } = getProductAttributes(lineItemUpdated);
        const itemName = lineItemUpdated.name['en-US'];
        
        utagData = {
          price: [
            ...utagData.price,
            lineItemUpdated?.price?.value?.centAmount / 100,
          ],
          originalPrice: [
            ...utagData.originalPrice,
            (lineItemUpdated?.price?.value?.centAmount + itemDiscount) / 100,
          ],
          parentCategory: [...utagData.parentCategory, parentCategory],
          subCategory: [...utagData.subCategory, subCategory],
          productName: [...utagData.productName, itemName],
          productId: [...utagData.productId, lineItemUpdated.variant.sku],
          productBrand: [...utagData.productBrand, 'Purple'],
          productColor: [...utagData.productColor, variantColor],
          productSize: [...utagData.productSize, variantSize],
          productVariant: [
            ...utagData.productVariant,
            productVariantExtraAttributes,
          ],
          productQuantity: [...utagData.productQuantity, 1],
          productDiscount: [...utagData.productDiscount, itemDiscount],
          producItemListId: [...utagData.producItemListId, itemListId],
          productItemListName: [...utagData.productItemListName, itemListName],
        };
      }

      if (warrantyLineItemUpdated) {
        const {
          custom: { fields: selectedWarrantyPlan },
        } = warrantyLineItemUpdated;
        const { warrantyPlanId, warrantyPrice } = selectedWarrantyPlan;

        const warrantyDuration = warrantyPlanId.split('-')[2];

        utagData = {
          price: [...utagData.price, warrantyPrice / 100],
          originalPrice: [...utagData.originalPrice, warrantyPrice / 100],
          parentCategory: [...utagData.parentCategory, 'Extend Protection'],
          subCategory: [
            ...utagData.subCategory,
            `${Object.values(lineItemUpdated.name)[0]} Protection`,
          ],
          productName: [
            ...utagData.productName,
            `Extend Protection ${warrantyDuration}Y`,
          ],
          productId: [...utagData.productId, warrantyPlanId],
          productBrand: [...utagData.productBrand, 'Extend'],
          productColor: [...utagData.productColor, null],
          productSize: [...utagData.productSize, null],
          productVariant: [...utagData.productVariant, null],
          productQuantity: [...utagData.productQuantity, 1],
          productDiscount: [...utagData.productDiscount, 0],
          producItemListId: [...utagData.producItemListId, itemListId],
          productItemListName: [...utagData.productItemListName, itemListName],
        };
      }
      window.utag?.link({
        event_name:
          prevQuantity < updatedQuantity ? 'Add to Cart' : 'Remove from cart',
        item_list_id: itemListId,
        item_list_name: itemListName,
        product_item_list_id: utagData.producItemListId,
        product_item_list_name: utagData.productItemListName,
        product_id: utagData.productId,
        product_name: utagData.productName,
        product_brand: utagData.productBrand,
        product_category: utagData.parentCategory,
        product_sub_category: utagData.subCategory,
        product_quantity: utagData.productQuantity,
        product_price: utagData.price,
        product_original_price: utagData.originalPrice,
        product_color: utagData.productColor,
        product_size: utagData.productSize,
        product_variant: utagData.productVariant,
        product_discount_value: utagData.productDiscount,
      });
      break;
    }
    case 'cart/addWarrantyPlanToLineItem/fulfilled': {
      const { warrantyPlanId, warrantyPrice, lineItemId } = args;
      const { lineItems } = cartDataBeforeLineitemChange;
      const lineItemAssociated = lineItems.find(
        (lineItem) => lineItem.id === lineItemId,
      );

      if (warrantyPlanId) {
        const warrantyDuration = warrantyPlanId.split('-')[2];
        const warrantyTotalPrice =
          (warrantyPrice / 100) * lineItemAssociated.quantity;

        utagData = {
          price: [...utagData.price, warrantyTotalPrice],
          parentCategory: [
            ...utagData.parentCategory,
            `${lineItemAssociated.name['en-US']} Extend Protection`,
          ],
          productName: [
            ...utagData.productName,
            `Extend Protection ${warrantyDuration}`,
          ],
          productId: [...utagData.productId, warrantyPlanId],
          productBrand: [...utagData.productBrand, 'Extend'],
          productQuantity: [...utagData.productQuantity, 1],
        };

        window.utag?.link({
          event_name: 'add to cart',
          product_name: utagData.productName,
          product_brand: utagData.productBrand,
          product_category: utagData.parentCategory,
          product_quantity: utagData.productQuantity,
          product_price: utagData.price,
        });
      }

      break;
    }
    case 'cart/removeLineItem/fulfilled': {
      const { lineItems, customLineItems } = cartDataBeforeLineitemChange;
      const [lineItemRemoved] = lineItems.filter(
        (lineItem) => lineItem.id === args.lineItemId,
      );
      const itemListId = ITEM_LIST_IDS_TEALIUM.cartRemoveItem;
      const itemListName = ITEM_LIST_IDS_TEALIUM.cartRemoveItem
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (char) => char.toUpperCase());

      // handle multiple lineitems with sku and only selected number of item has discount
      // eg: 3 harmony pillows in the cart with discounts for 2 H. pillows.
      const checkIfMultipleQuantity = lineItems.filter(
        (lineItem) =>
          lineItem.variant.sku === lineItemRemoved?.variant.sku &&
          lineItem.id !== args.lineItemId,
      );

      let warrantyLineItem = false;
      const warrantyLineItems = customLineItems.filter(
        (customLineItem) =>
          customLineItem.custom?.fields?.type ===
          WARRANTY_CUSTOM_LINE_ITEM_TYPE,
      );
      const [warrantyLineItemRemoved] = warrantyLineItems.length
        ? warrantyLineItems
        : [];

      if (lineItemRemoved) {
        const quantity = lineItemRemoved.quantity - (args?.quantity || 0);
        const {
          parentCategory,
          subCategory,
          variantColor,
          variantSize,
          productVariantExtraAttributes,
        } = getProductAttributes(lineItemRemoved);

        const discountJson = lineItemRemoved?.custom?.fields?.discounts_json;
        const itemDiscount =
          discountJson &&
          JSON.parse(discountJson)?.reduce(
            (totalLine, discount) => totalLine + discount.cent_amount,
            0,
          );
        const itemName = lineItemRemoved.name['en-US'];

        // update tealium added location
        tealiumStorageService.setCookieForTealiumLineItemAddedLocation({
          lineItemId: lineItemRemoved.variant.sku,
          itemAddedLocation: null,
        });
        const itemPrice = lineItemRemoved?.price?.value
          ?.centAmount;
        const productPrice =
          (!checkIfMultipleQuantity.length ?  itemPrice : (itemPrice + itemDiscount))/ 100;

        utagData = {
          price: [...utagData.price, productPrice],
          originalPrice: [
            ...utagData.originalPrice,
            (lineItemRemoved?.price?.value?.centAmount + itemDiscount) / 100,
          ],
          parentCategory: [...utagData.parentCategory, parentCategory],
          subCategory: [...utagData.subCategory, subCategory],
          productName: [...utagData.productName, itemName],
          productId: [...utagData.productId, lineItemRemoved.variant.sku],
          productBrand: [...utagData.productBrand, 'Purple'],
          productColor: [...utagData.productColor, variantColor],
          productSize: [...utagData.productSize, variantSize],
          productVariant: [
            ...utagData.productVariant,
            productVariantExtraAttributes,
          ],
          productQuantity: [...utagData.productQuantity, quantity],
          ...(!checkIfMultipleQuantity.length
            ? {
                productDiscount: [...utagData.productDiscount, itemDiscount],
              }
            : {}),
          producItemListId: [...utagData.producItemListId, itemListId],
          productItemListName: [...utagData.productItemListName, itemListName],
        };
      }
      if (warrantyLineItemRemoved && !tealiumFiredForWarranty) {
        warrantyLineItem = true;

        const {
          custom: { fields: selectedWarrantyPlan },
        } = warrantyLineItemRemoved;
        const { warrantyPlanId, warrantyPrice, lineItemsIds } =
          selectedWarrantyPlan;

        const foundItem = lineItems.find(
          (lineItem) => lineItem.id === lineItemsIds[0],
        );

        const itemQuantity =
          warrantyLineItems.length > foundItem.quantity
            ? foundItem.quantity
            : warrantyLineItems.length;

        const warrantyDuration = warrantyPlanId.split('-')[2];
        const warrantyTotalPrice = (warrantyPrice / 100) * itemQuantity;

        utagData = {
          price: [...utagData.price, warrantyTotalPrice],
          originalPrice: [...utagData.originalPrice, warrantyTotalPrice],
          parentCategory: [...utagData.parentCategory, `Extend Protection`],
          subCategory: [
            ...utagData.subCategory,
            `${Object.values(foundItem.name)[0]} Protection`,
          ],
          productName: [
            ...utagData.productName,
            `Extend Protection ${warrantyDuration}`,
          ],
          productId: [...utagData.productId, warrantyPlanId],
          productBrand: [...utagData.productBrand, 'Extend'],
          productQuantity: [...utagData.productQuantity, itemQuantity],
          productColor: [...utagData.productColor, null],
          productSize: [...utagData.productSize, null],
          productVariant: [...utagData.productVariant, null],
          productDiscount: [...utagData.productDiscount, null],
          producItemListId: [...utagData.producItemListId, itemListId],
          productItemListName: [...utagData.productItemListName, itemListName],
        };
      }
      if (utagData.productId.length) {
        window.utag?.link({
          event_name: 'Remove from cart',
          item_list_id: itemListId,
          item_list_name: itemListName,
          product_item_list_id: utagData.producItemListId,
          product_item_list_name: utagData.productItemListName,
          product_id: utagData.productId,
          product_name: utagData.productName,
          product_brand: utagData.productBrand,
          product_category: utagData.parentCategory,
          product_sub_category: utagData.subCategory,
          product_quantity: utagData.productQuantity,
          product_price: utagData.price,
          product_original_price: utagData.originalPrice,
          product_color: utagData.productColor,
          product_size: utagData.productSize,
          product_variant: utagData.productVariant,
          product_discount_value: utagData.productDiscount,
        });
        tealiumFiredForWarrantyFlag = !!warrantyLineItem;
      }
      break;
    }
    case 'cart/getCartAOVs/fulfilled': {
      const { status: aovStatus, addons: aovAddons } = aov || {};
      if (
        !tealiumEventsFired.cartAOV &&
        aovStatus === 'succeeded' &&
        aovAddons?.length
      ) {
        aovAddons.forEach((addon) => {
          const { variant } = addon;
          const {
            parentCategory,
            subCategory,
            variantColor,
            variantSize,
            productVariantExtraAttributes,
          } = getProductAttributes(addon);
          const [price] = variant.price;
          const [variantDiscount] = price.discounts;

          const productPrice = price.totalPrice.centAmount / 100;
          const itemDiscount = variantDiscount
            ? variantDiscount.value.centAmount / 100
            : 0;

          utagData = {
            price: [...utagData.price, productPrice],
            originalPrice: [
              ...utagData.originalPrice,
              productPrice + itemDiscount,
            ],
            parentCategory: [...utagData.parentCategory, parentCategory],
            subCategory: [...utagData.subCategory, subCategory],
            productName: [...utagData.productName, addon.name['en-US']],
            productId: [...utagData.productId, addon.variant.sku],
            productBrand: [...utagData.productBrand, 'Purple'],
            productColor: [...utagData.productColor, variantColor],
            productSize: [...utagData.productSize, variantSize],
            productVariant: [
              ...utagData.productVariant,
              productVariantExtraAttributes,
            ],
            productQuantity: [...utagData.productQuantity, price.quantity],
            productDiscount: [...utagData.productDiscount, itemDiscount],
            producItemListId: [
              ...utagData.producItemListId,
              ITEM_LIST_IDS_TEALIUM.cartRecommendedProducts,
            ],
            productItemListName: [
              ...utagData.productItemListName,
              ITEM_LIST_IDS_TEALIUM.cartRecommendedProducts
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, (char) => char.toUpperCase())
            ],
          };
        });
        window.utag?.link({
          event_name: 'view cart - recommended products',
          item_list_id: ITEM_LIST_IDS_TEALIUM.cartRecommendedProducts,
          item_list_name: ITEM_LIST_IDS_TEALIUM.cartRecommendedProducts
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (char) => char.toUpperCase()),
          product_item_list_id: utagData.producItemListId,
          product_item_list_name: utagData.productItemListName,
          product_id: utagData.productId,
          product_name: utagData.productName,
          product_brand: utagData.productBrand,
          product_category: utagData.parentCategory,
          product_sub_category: utagData.subCategory,
          product_price: utagData.price,
          product_original_price: utagData.originalPrice,
          product_discount_value: utagData.productDiscount,
          product_color: utagData.productColor,
          product_size: utagData.productSize,
          product_variant: utagData.productVariant,
          product_quantity: utagData.productQuantity,
        });
        tealiumEventsFired.cartAOV = true;
      }
      break;
    }
    case 'accounts/setCustomerId/fulfilled': {
      if (args) {
        window.utag?.link({
          event_name: 'Customer Authentication',
          sign_in: true,
        });
      } else if (args === false) {
        window.utag?.link({
          event_name: 'Customer Authentication',
          sign_out: true,
        });
      }
      break;
    }
    case 'cart/getActiveCart/fulfilled': {
      const { lineItems, customLineItems } = cart || {};
      if (!tealiumEventsFired.cart && window.location.pathname === '/cart') {
        let cartValue = 0;
        let promoCodes = null;
        if (lineItems?.length) {
          const subtotal = lineItems?.length
            ? calculateSubtotal({ lineItems })
            : 0;

          const warrantyItems = lineItems?.length
            ? customLineItems.filter(
                (customLineItem) =>
                  customLineItem.custom?.fields?.type ===
                  WARRANTY_CUSTOM_LINE_ITEM_TYPE,
              )
            : null;

          const totalWarranties = warrantyItems
            ? calculateSubtotal({ lineItems: warrantyItems })
            : 0;
          lineItems?.forEach((lineItem) => {
            // fetch cart item added location from localstorage
            const addedLocation = JSON.parse(
              localStorage.getItem(TEALIUM_ADDED_LOCATION_COOKIE),
            );
            const lineItemLocation = addedLocation?.find(
              (location) => location.lineItemId === lineItem.variant.sku,
            );
            const {
              parentCategory,
              subCategory,
              variantColor,
              variantSize,
              productVariantExtraAttributes,
            } = getProductAttributes(lineItem);
            const discountJson = lineItem?.custom?.fields?.discounts_json;
            const itemDiscount =
              discountJson &&
              JSON.parse(discountJson)?.reduce(
                (totalLine, discount) => totalLine + discount.cent_amount,
                0,
              );
            const couponCodes =
              discountJson &&
              JSON.parse(discountJson)?.reduce(
                (allCoupons, discount) =>
                  discount.coupon_code
                    ? [...allCoupons, discount.coupon_code]
                    : allCoupons,
                [],
              );
            promoCodes = couponCodes.length ? couponCodes : null;
            const productOriginalPrice =
              ((lineItem?.price?.value?.centAmount + itemDiscount) *
                lineItem.quantity) /
              100;

            utagData = {
              price: [
                ...utagData.price,
                (lineItem?.price?.value?.centAmount * lineItem.quantity) / 100,
              ],
              originalPrice: [...utagData.originalPrice, productOriginalPrice],
              parentCategory: [...utagData.parentCategory, parentCategory],
              subCategory: [...utagData.subCategory, subCategory],
              productName: [...utagData.productName, lineItem.name['en-US']],
              productId: [...utagData.productId, lineItem.variant.sku],
              productBrand: [...utagData.productBrand, 'Purple'],
              productColor: [...utagData.productColor, variantColor],
              productSize: [...utagData.productSize, variantSize],
              productVariant: [
                ...utagData.productVariant,
                productVariantExtraAttributes,
              ],
              productQuantity: [...utagData.productQuantity, lineItem.quantity],
              productDiscount: [...utagData.productDiscount, itemDiscount],
              itemListId: [
                ...utagData.itemListId,
                lineItemLocation?.item_list_id,
              ],
              itemListName: [
                ...utagData.itemListName,
                lineItemLocation?.item_list_id
                  ? lineItemLocation?.item_list_id
                      .replace(/_/g, ' ')
                      .replace(/\b\w/g, (char) => char.toUpperCase())
                  : null,
              ],
              itemAddedLocation: [
                ...utagData.itemAddedLocation,
                lineItemLocation?.item_added_location,
              ],
              producItemListId: [
                ...utagData.producItemListId,
                lineItemLocation?.item_list_id,
              ],
              productItemListName: [
                ...utagData.productItemListName,
                lineItemLocation?.item_list_id
                  ? lineItemLocation?.item_list_id
                      .replace(/_/g, ' ')
                      .replace(/\b\w/g, (char) => char.toUpperCase())
                  : null,
              ],
            };
          });
          cartValue = (subtotal + totalWarranties) / 100;
          if (warrantyItems) {
            warrantyItems.forEach((warranty) => {
              const {
                custom: { fields: selectedWarrantyPlan },
              } = warranty;
              const {
                warrantyPlanId,
                warrantyPrice,
                warrantyTerm,
                lineItemsIds,
              } = selectedWarrantyPlan;
              const warrantyDurationInYears = warrantyTerm / 12;
              const totalWarrantPrice =
                (warrantyPrice * warranty.quantity) / 100;

              utagData = {
                price: [...utagData.price, totalWarrantPrice],
                originalPrice: [...utagData.originalPrice, totalWarrantPrice],
                parentCategory: [
                  ...utagData.parentCategory,
                  'Extend Protection',
                ],
                subCategory: [
                  ...utagData.subCategory,
                  `${
                    lineItems.find((item) => item.id === lineItemsIds[0])
                      ?.name['en-US']
                  } Protection`,
                ],
                productName: [
                  ...utagData.productName,
                  `Extend Protection ${warrantyDurationInYears}Y`,
                ],
                productId: [...utagData.productId, warrantyPlanId],
                productBrand: [...utagData.productBrand, 'Extend'],
                productColor: [...utagData.productColor, null],
                productSize: [...utagData.productSize, null],
                productVariant: [...utagData.productVariant, null],
                productQuantity: [
                  ...utagData.productQuantity,
                  warranty.quantity,
                ],
                productDiscount: [...utagData.productDiscount, 0],
              };
            });
          }
        }
        window?.utag?.view({
          event_name: 'View Cart',
          page_name: 'Cart',
          page_title: document.title,
          page_type: 'Cart',
          cart_value: cartValue,
          ...(promoCodes ? { promo_code: promoCodes } : {}),
          ...(cartValue > 0
            ? {
                item_list_id: utagData.itemListId,
                item_list_name: utagData.itemListName,
                item_added_location: utagData.itemAddedLocation,
                product_item_list_id: utagData.producItemListId,
                product_item_list_name: utagData.productItemListName,
                product_id: utagData.productId,
                product_name: utagData.productName,
                product_brand: utagData.productBrand,
                product_quantity: utagData.productQuantity,
                product_price: utagData.price,
                product_original_price: utagData.originalPrice,
                product_color: utagData.productColor,
                product_size: utagData.productSize,
                product_variant: utagData.productVariant,
                product_discount_value: utagData.productDiscount,
                product_category: utagData.parentCategory,
                product_sub_category: utagData.subCategory,
              }
            : {}),
        });
        tealiumEventsFired.cart = true;
      }
      break;
    }
    default:
      break;
  }
  return tealiumFiredForWarrantyFlag;
};

const tealiumEvents = (store) => {
  // Local state for temporary cart data
  let cartDataBeforeLineitemChange = null;
  let tealiumFiredForWarranty = false;
  return (next) => async (action) => {
    const result = next(action);
    const { cart, order, aov } = store.getState();

    if (SUBSCRIBED_TEALIUM_CART_PENDING_EVENTS.includes(action.type)) {
      cartDataBeforeLineitemChange = cart;
    }

    if (SUBSCRIBED_TEALIUM_FULFILLED_EVENTS.includes(action.type)) {
      tealiumFiredForWarranty = tealiumUpdatedEventData({
        actionType: action.type,
        args: action.meta?.arg,
        cartDataBeforeLineitemChange,
        tealiumFiredForWarranty,
        cart,
        order,
        aov,
      });
    }

    return result;
  };
};

export default tealiumEvents;
