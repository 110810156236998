import ordersService from './orders';
import draftOrdersService from './draftOrders';

const getServiceType =
  (isDraft) => (isDraft ? draftOrdersService : ordersService);

const getServiceName =
  (isDraft) => (isDraft ? 'draftOrdersService' : 'ordersService');

const getMethodService =
  (isDraft, methodName) => getServiceType(isDraft)[methodName];

const callToMethod = ({
  cartId,
  isDraft,
  methodName,
  paramsObject,
}) => {
  const method = getMethodService(isDraft, methodName);

  if (!method) {
    throw new TypeError(
      // eslint-disable-next-line max-len
      `Invalid param sent: method "${methodName}" does not exist in "${getServiceName(isDraft)}"`,
    );
  }

  if (!isDraft) {
    return method(paramsObject);
  }

  return method(cartId, paramsObject);
};

const methodsMap = Object.freeze({
  placeOrder: 'placeOrder',
  authorizeSession: 'authorizeSession',
  updateCheckoutSession: 'updateCheckoutSession',
});

const ordersMasterService = {
  callToMethod,
  methodsMap,
};

export default ordersMasterService;
