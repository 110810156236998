/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import cartMasterService from '../../services/cart/cartMasterService';
import cartService from '../../services/cart';
import draftCartService from '../../services/cart/draftCart';
import catalogService from '../../services/catalog';
import { retryFn } from '../../utils';

import {
  ZIP_ERROR,
  SHIPPING_ADDRESS_UNEXPECTED_ERROR,
  BILLING_ADDRESS_UNEXPECTED_ERROR,
} from '../../blocks/Checkout/SteppedCheckout/constants';

import { IS_OUT_OF_STOCK } from '../../utils/common/constants';
import {
  getGuaranteeItemsString,
  parseGuaranteeItemsResponse,
} from '../../utils/cart';

const CATALOG_GET_PRODUCT_BY_KEY_RETRY_COUNT = 3;
const CATALOG_GET_PRODUCT_BY_KEY_DELAY_MS = 500;

export const setCart = createAction('setCart');

export const addPromoError = createAction('addPromoError');
export const removePromoError = createAction('removePromoError');
export const addShippingAddressErrorCode = createAction(
  'addShippingAddressErrorCode',
);

export const getActiveCart = createAsyncThunk('cart/getActiveCart', () =>
  cartService.getActiveCart(),
);

export const getProductPopularity = createAsyncThunk(
  'cart/getProductPopularity',
  ({ productKeys }) =>
    Promise.all(
      productKeys.map((productKey) =>
        cartService.getProductPopularity({ productKey }),
      ),
    ),
);

export const getWarrantyPlans = createAsyncThunk('cart/getWarrantyPlans', () =>
  cartService.getWarrantyPlans(),
);

export const getAvailableDiscountCodeForAbandonedCarts = createAsyncThunk(
  'cart/getAvailableDiscountCodeForAbandonedCarts',
  () => cartService.getAvailableDiscountCodeForAbandonedCarts(),
);
export const getAvailableDiscountCodeForAbandonedCheckout = createAsyncThunk(
  'cart/getAvailableDiscountCodeForAbandonedCheckout',
  (params) => cartService.getAvailableDiscountCodeForAbandonedCheckout(params),
);

export const getDraftCart = createAsyncThunk(
  'cart/getDraftCart',
  async (cartId, { rejectWithValue }) => {
    try {
      return await draftCartService.getDraftCart(cartId);
    } catch (error) {
      return rejectWithValue({
        errorCode: error.apiErrors[0].status,
      });
    }
  },
);

const convertPreviousLineItem = ({ id, variant, quantity, price, custom }) => ({
  id,
  quantity,
  price,
  custom,
  variant: {
    sku: variant.sku,
    images: variant.images,
    attributes: variant.attributes.filter(
      (attribute) =>
        attribute.name === 'productUrl' || attribute.name === 'title',
    ),
  },
});
const convertPreviousCustomLineItem = ({
  id,
  totalPrice,
  quantity,
  price,
  custom,
  name,
}) => ({
  id,
  quantity,
  price,
  custom,
  totalPrice,
  name,
});

export const addLineItems = createAsyncThunk(
  'cart/addLineItems',
  async ({ lineItems }, { getState, rejectWithValue }) => {
    try {
      let oldCart = getState().cart;

      if (!oldCart.id) {
        oldCart = await cartService.getActiveCart();
      }

      const previousLineItems = oldCart
        ? oldCart.lineItems.map(convertPreviousLineItem)
        : [];

      const previousCustomLineItems = oldCart
        ? oldCart.customLineItems.map(convertPreviousCustomLineItem)
        : [];

      const cart = await cartService.addLineItems(lineItems);
      return {
        ...cart,
        previousLineItems,
        previousCustomLineItems,
      };
    } catch (error) {
      return rejectWithValue({
        errorCode: error.apiErrors[0].code,
      });
    }
  },
);

export const removeLineItem = createAsyncThunk(
  'cart/removeLineItem',
  ({ lineItemId }) => cartService.removeLineItem(lineItemId),
);

export const addWarrantyPlanToLineItem = createAsyncThunk(
  'cart/addWarrantyPlanToLineItem',
  ({ lineItemId, warrantyPlanId }) =>
    cartService.addWarrantyPlanToLineItem({ lineItemId, warrantyPlanId }),
);

export const changeLineItemQuantity = createAsyncThunk(
  'cart/changeLineItemQuantity',
  ({ lineItemId, quantity }) =>
    cartService.changeLineItemQuantity(lineItemId, quantity),
);

export const removeDefaultGifts = createAsyncThunk(
  'cart/removeDefaultGifts',
  async ({ defaultGifts }, { getState }) => {
    let { cart } = getState();

    for (const defaultGift of defaultGifts) {
      const currentLineItem = cart.lineItems.find(
        (lineItem) =>
          lineItem.productId === defaultGift.productId &&
          lineItem.variant.sku === defaultGift.sku,
      );

      if (currentLineItem.quantity === 1) {
        cart = await cartService.removeLineItem(currentLineItem.id);
      } else {
        cart = await cartService.changeLineItemQuantity(
          currentLineItem.id,
          currentLineItem.quantity - 1,
        );
      }
    }
    return cart;
  },
);

const getShippingErrorCodeFromCode = (code) => {
  switch (code) {
    case '502':
    case '500':
    case undefined:
      return SHIPPING_ADDRESS_UNEXPECTED_ERROR;
    default:
      return code;
  }
};
const getBillingErrorCodeFromCode = (code) => {
  switch (code) {
    case '502':
    case '500':
      return SHIPPING_ADDRESS_UNEXPECTED_ERROR;
    default:
      return code;
  }
};

export const setShippingAddressSteppedCheckout = createAsyncThunk(
  'cart/setShippingAddressSteppedCheckout',
  async ({ shippingAddress }, { getState, rejectWithValue }) => {
    const { id, isDraft } = getState().cart;

    try {
      const setShippingAddressResult = await cartMasterService.callToMethod({
        cartId: id,
        isDraft,
        methodName: cartMasterService.methodsMap.setShippingAddress,
        paramsObject: { shippingAddress },
      });

      return setShippingAddressResult;
    } catch (error) {
      const errorCode = error.apiErrors?.some(
        (apiError) => apiError.title?.indexOf('Zip') > 0,
      )
        ? ZIP_ERROR
        : getShippingErrorCodeFromCode(error.apiErrors?.[0]?.code);
      return rejectWithValue({
        shippingAddressErrorCode: errorCode,
      });
    }
  },
);

export const setBillingAddress = createAsyncThunk(
  'cart/setBillingAddress',
  async ({ billingAddress }, { getState, rejectWithValue }) => {
    const { id, isDraft } = getState().cart;

    try {
      return await cartMasterService.callToMethod({
        cartId: id,
        isDraft,
        methodName: cartMasterService.methodsMap.setBillingAddress,
        paramsObject: { billingAddress },
      });
    } catch (error) {
      console.error(
        `CheckoutError: setBillingAddress - ${JSON.stringify(error)}`,
      );
      return rejectWithValue({
        billingAddressErrorCode: getBillingErrorCodeFromCode(
          error.apiErrors?.[0]?.code,
        ),
      });
    }
  },
);

export const removeBillingAddress = createAsyncThunk(
  'cart/removeBillingAddress',
  () => cartService.removeBillingAddress(),
);

export const updateVariantLineItem = createAsyncThunk(
  'cart/updateVariantLineItem',
  ({ lineItemId, sku }) => cartService.updateVariantLineItem(lineItemId, sku),
);

export const getCartAOVs = createAsyncThunk('cart/getCartAOVs', () =>
  cartService.getCartAOVs(),
);

export const setShippingMethods = createAsyncThunk(
  'cart/setShippingMethods',
  ({ selectedShippingMethods }, { getState }) => {
    const { id, isDraft } = getState().cart;

    return cartMasterService.callToMethod({
      cartId: id,
      isDraft,
      methodName: cartMasterService.methodsMap.setShippingMethods,
      paramsObject: { selectedShippingMethods },
    });
  },
);

export const addPromoCode = createAsyncThunk(
  'cart/addPromoCode',
  async (promoCode, { rejectWithValue }) => {
    try {
      return await cartService.addPromoCode(promoCode);
    } catch (error) {
      return rejectWithValue({
        errorCode: error.metadata?.error_code || error.message,
      });
    }
  },
);

export const removePromoCode = createAsyncThunk(
  'cart/removePromoCode',
  (promoCode) => cartService.removePromoCode(promoCode),
);

export const setPendingSaveShippingAddress = createAction(
  'cart/setPendingSaveShippingAddress',
);
export const setIsValidatingShippingAddress = createAction(
  'cart/setIsValidatingShippingAddress',
);

export const addGiftCard = createAsyncThunk(
  'cart/addGiftCard',
  async ({ giftCardNumber, securityCode }, { getState, rejectWithValue }) => {
    const { id, isDraft } = getState().cart;

    try {
      return await cartMasterService.callToMethod({
        cartId: id,
        isDraft,
        methodName: cartMasterService.methodsMap.addGiftCard,
        paramsObject: { giftCardNumber, securityCode },
      });
    } catch (error) {
      return rejectWithValue({
        errorCode: error.metadata?.error_code || error.message,
      });
    }
  },
);

export const removeGiftCard = createAsyncThunk(
  'cart/removeGiftCard',
  ({ giftCardCode }, { getState }) => {
    const { id, isDraft } = getState().cart;

    return cartMasterService.callToMethod({
      cartId: id,
      isDraft,
      methodName: cartMasterService.methodsMap.removeGiftCard,
      paramsObject: { giftCardCode },
    });
  },
);

export const removeGiftCardsFromCart = createAsyncThunk(
  'cart/removeGiftCardsFromCart',
  () => cartService.removeGiftCardsFromCart(),
);

export const removeGiftCardError = createAction('removeGiftCardError');
export const removeGiftCardBalance = createAction('removeGiftCardBalance');

export const getGiftCardBalance = createAsyncThunk(
  'cart/getGiftCardBalance',
  async ({ giftCardNumber, securityCode }, { rejectWithValue }) => {
    try {
      return await cartService.giftCardBalance({
        giftCardNumber,
        securityCode,
      });
    } catch (error) {
      return rejectWithValue({
        errorCode: error.metadata?.error_code || error.message,
      });
    }
  },
);

export const checkLineItemsForOOSVariants = createAsyncThunk(
  'cart/checkLineItemsForOOSVariants',
  async (_, { getState }) => {
    const { lineItems } = getState().cart;
    const productKeysOnCart = Array.from(
      new Set(lineItems?.map((item) => item.productKey) ?? []),
    );

    const oosVariantsForProductsOnCart =
      (
        await Promise.all(
          productKeysOnCart.flatMap(async (productKey) => {
            const productByKeyResponse = await retryFn({
              fn: catalogService.getProductByKey,
              args: productKey,
              retriesLeft: CATALOG_GET_PRODUCT_BY_KEY_RETRY_COUNT,
              delayTime: CATALOG_GET_PRODUCT_BY_KEY_DELAY_MS,
            });

            const productOutOfStockVariants = [
              ...productByKeyResponse?.variants?.filter(
                (variant) =>
                  variant.attributes?.find(
                    (attribute) => attribute.name === IS_OUT_OF_STOCK,
                  )?.value,
              ),
              ...(productByKeyResponse.masterVariant?.attributes?.find(
                (attribute) => attribute.name === IS_OUT_OF_STOCK,
              )?.value
                ? [productByKeyResponse.masterVariant]
                : []),
            ];

            return productOutOfStockVariants;
          }),
        )
      )?.flat?.() ?? [];

    const outOfStockLineItems =
      lineItems?.filter((lineItem) =>
        oosVariantsForProductsOnCart.find(
          (oosVariant) => oosVariant.sku === lineItem.variant?.sku,
        ),
      ) ?? [];

    return outOfStockLineItems;
  },
);

/* #region TaxEstimation */
export const getTaxEstimation = createAsyncThunk(
  'cart/setEstimatedTax',
  async ({ zipCode }, { rejectWithValue }) => {
    try {
      const taxAmount = await cartService.getTaxEstimation(zipCode);

      return { taxAmount, zipCode };
    } catch (error) {
      return rejectWithValue({
        errorCode: error.metadata?.error_code || error.message,
      });
    }
  },
);
export const removeTaxEstimation = createAction('removeTaxEstimation');
export const addTaxEstimationError = createAction('addTaxEstimationError');
export const removeTaxEstimationError = createAction(
  'removeTaxEstimationError',
);

export const setShowOutOfStockToaster = createAction(
  'setShowOutOfStockToaster',
);
export const getAbandonedCartToasts = createAsyncThunk(
  'getAbandonedCartToasts',
  async (params, { rejectWithValue }) => {
    try {
      const cartToasts = await cartService.getAbandonedCartToasts();
      return cartToasts;
    } catch (err) {
      return rejectWithValue({
        errorCode: err.metadata?.error_code || err.message,
      });
    }
  },
);

export const getGuaranteeItems = createAsyncThunk(
  'cart/getGuaranteeItems',
  async (_, { rejectWithValue, getState }) => {
    const { lineItems } = getState().cart;

    if (!lineItems?.length) {
      return [];
    }

    try {
      const guaranteeItemsString = getGuaranteeItemsString(lineItems);
      const guaranteeItemsResponse =
        await cartService.getGuaranteeItems(guaranteeItemsString);
      return parseGuaranteeItemsResponse(lineItems, guaranteeItemsResponse);
    } catch (error) {
      return rejectWithValue({
        errorCode: error.apiErrors[0].status,
      });
    }
  },
);

export const storeRecentlyAddedItems = createAction('storeRecentlyAddedItems');

export const setBMSMCurrentQualifiedPercentages = createAction('setBMSMCurrentQualifiedPercentages');
export const setBMSMEstimatedQualifiedPercentages = createAction('setBMSMEstimatedQualifiedPercentages');
export const setBMSMCurrentTier = createAction('setBMSMCurrentTier');
export const setBMSMRemaingAmount = createAction('setBMSMRemaingAmount');

/* #endregion TaxEstimation */

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    isDraft: false,
    warrantyPlans: [],
    lineItems: [],
    customLineItems: [],
    previousLineItems: [],
    previousCustomLineItems: [],
    outOfStockLineItems: [],
    productPopularity: [],
    totalPrice: {
      centAmount: 0,
      fractionDigits: 2,
      currencyCode: 'USD',
    },
    status: 'idle',
    showOutOfStockToaster: false,
    recentlyAddedItems: [],
    discountCodeForAbandonedCarts: {},
    discountCodeForAbandonedCheckout: {},
    buyMoreSaveMore: {
      currentQualifiedSavings: {},
      estimatedQualifiedSavings: {},
      currentTier: 0,
      remainingAmount: 0,
    },
    guaranteeItems: [],
  },
  reducers: {
    setPendingSaveShippingAddress: (state, action) => ({
      ...state,
      pendingSaveShippingAddress: action.payload,
    }),
    setIsValidatingShippingAddress: (state, action) => ({
      ...state,
      isValidatingShippingAddress: action.payload,
    }),
  },
  extraReducers: {
    /* *********** */
    /* ACTIVE CART */
    /* *********** */
    [getActiveCart.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [getActiveCart.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      previousLineItems: action?.payload?.lineItems || [],
      previousCustomLineItems: action?.payload?.customLineItems || [],
      isDraft: false,
      status: 'succeeded',
    }),
    [getActiveCart.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),
    /* *********** */
    /* DRAFT CART */
    /* *********** */
    [getDraftCart.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [getDraftCart.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      isDraft: true,
      status: 'succeeded',
    }),
    [getDraftCart.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),
    /* ********* */
    /* LINEITEMS */
    /* ********* */
    [addLineItems.pending]: (state) => ({
      ...state,
      addLineItemsError: undefined,
      status: 'loading',
    }),
    [addLineItems.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      addLineItemsError: undefined,
      status: 'succeeded',
    }),
    [addLineItems.rejected]: (state, action) => ({
      ...state,
      status: 'failed',
      addLineItemsError: action.payload?.errorCode,
    }),
    [removeLineItem.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [removeLineItem.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      taxedPrice: action.payload.taxedPrice ?? undefined,
      previousLineItems: action?.payload?.lineItems || [],
      previousCustomLineItems: action?.payload?.customLineItems || [],
      status: 'succeeded',
    }),
    [removeLineItem.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),
    [changeLineItemQuantity.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [changeLineItemQuantity.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      previousLineItems: action?.payload?.lineItems || [],
      previousCustomLineItems: action?.payload?.customLineItems || [],
      status: 'succeeded',
    }),
    [changeLineItemQuantity.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),
    [removeDefaultGifts.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [removeDefaultGifts.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      previousLineItems: action?.payload?.lineItems || [],
      previousCustomLineItems: action?.payload?.customLineItems || [],
      status: 'succeeded',
    }),
    [removeDefaultGifts.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),
    [updateVariantLineItem.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [updateVariantLineItem.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      previousLineItems: action?.payload?.lineItems || [],
      previousCustomLineItems: action?.payload?.customLineItems || [],
      status: 'succeeded',
    }),
    [updateVariantLineItem.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),
    // Set shipping address stepped checkout
    [setShippingAddressSteppedCheckout.pending]: (state) => ({
      ...state,
      shippingAddressErrorCode: undefined,
      status: 'loading',
    }),
    [setShippingAddressSteppedCheckout.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      pendingSaveShippingAddress: undefined,
      shippingAddressErrorCode: undefined,
      status: 'succeeded',
    }),
    [setShippingAddressSteppedCheckout.rejected]: (state, action) => ({
      ...state,
      shippingAddressErrorCode: action.payload.shippingAddressErrorCode,
      status: 'failed',
    }),
    storeRecentlyAddedItems: (state, action) => ({
      ...state,
      recentlyAddedItems: action.payload,
    }),
    addShippingAddressErrorCode: (state, action) => ({
      ...state,
      shippingAddressErrorCode: action.payload,
    }),
    removeShippingAddressErrorCode: (state) => ({
      ...state,
      shippingAddressErrorCode: undefined,
    }),
    removeBillingAddressErrorCode: (state) => ({
      ...state,
      billingAddressErrorCode: undefined,
    }),
    /* *************** */
    /* BILLING ADDRESS */
    /* *************** */
    [setBillingAddress.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [setBillingAddress.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      billingAddressErrorCode: undefined,
      status: 'succeeded',
    }),
    [setBillingAddress.rejected]: (state, action) => ({
      ...state,
      billingAddress:
        action.payload.billingAddressErrorCode ===
        BILLING_ADDRESS_UNEXPECTED_ERROR
          ? undefined
          : state.billingAddress,
      billingAddressErrorCode: action.payload.billingAddressErrorCode,
      status: 'failed',
    }),
    [removeBillingAddress.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [removeBillingAddress.fulfilled]: (
      { billingAddress, ...state },
      action,
    ) => ({
      ...state,
      ...action.payload,
      status: 'succeeded',
    }),
    [removeBillingAddress.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),
    /* **************** */
    /* SHIPPING METHODS */
    /* **************** */
    [setShippingMethods.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [setShippingMethods.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      status: 'succeeded',
    }),
    [setShippingMethods.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),
    [addPromoCode.pending]: (state) => ({
      ...state,
      status: 'loading',
      promoErrorCode: undefined,
    }),
    [addPromoCode.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      status: 'succeeded',
    }),
    [addPromoCode.rejected]: (state, action) => ({
      ...state,
      status: 'failed',
      promoErrorCode: action.payload.errorCode,
    }),
    [removePromoCode.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [removePromoCode.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      status: 'succeeded',
    }),
    [removePromoCode.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),
    setCart: (state, data) => ({
      ...state,
      ...data.payload,
    }),
    addPromoError: (state, payload) => ({
      ...state,
      promoErrorCode: payload.errorCode,
    }),
    removePromoError: (state) => ({
      ...state,
      promoErrorCode: undefined,
    }),
    /* **************** */
    /* GIFT CARDS       */
    /* **************** */
    [addGiftCard.pending]: (state) => ({
      ...state,
      status: 'loading',
      giftCardErrorCode: undefined,
    }),
    [addGiftCard.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      status: 'succeeded',
    }),
    [addGiftCard.rejected]: (state, action) => ({
      ...state,
      status: 'failed',
      giftCardErrorCode: action.payload.errorCode,
    }),
    [removeGiftCard.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [removeGiftCard.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      status: 'succeeded',
    }),
    [removeGiftCard.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),
    [removeGiftCardsFromCart.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [removeGiftCardsFromCart.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      status: 'succeeded',
    }),
    [removeGiftCardsFromCart.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),
    removeGiftCardError: (state) => ({
      ...state,
      giftCardErrorCode: undefined,
    }),
    [getGiftCardBalance.pending]: (state) => ({
      ...state,
      status: 'loading',
      giftCardErrorCode: undefined,
    }),
    [getGiftCardBalance.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      status: 'succeeded',
      giftCardErrorCode: undefined,
    }),
    [getGiftCardBalance.rejected]: (state, action) => ({
      ...state,
      status: 'failed',
      giftCardErrorCode: action.payload.errorCode,
    }),
    removeGiftCardBalance: (state) => ({
      ...state,
      balanceAmount: undefined,
    }),
    /* ********************** */
    /* ESTIMATED TAX METHODS */
    /* ******************** */
    [getTaxEstimation.pending]: (state) => ({
      ...state,
      status: 'loading',
      estimatedTaxZipCode: undefined,
      estimatedTaxErrorCode: undefined,
    }),
    [getTaxEstimation.rejected]: (state, action) => ({
      ...state,
      status: 'failed',
      estimatedTaxErrorCode: action.payload.errorCode,
    }),
    [getTaxEstimation.fulfilled]: (state, action) => ({
      ...state,
      status: 'succeeded',
      estimatedTaxZipCode: action.payload.zipCode,
      estimatedTaxAmount: action.payload.taxAmount,
    }),
    /* ********************** */
    /* GET WARRANTY PLANS     */
    /* ********************** */
    [getWarrantyPlans.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [getWarrantyPlans.fulfilled]: (state, action) => ({
      ...state,
      warrantyPlans: action.payload || [],
      status: 'succeeded',
    }),
    [getWarrantyPlans.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),

    /* ********************** */
    /* GET PRODUCT POPULARITY */
    /* ********************** */
    [getProductPopularity.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [getProductPopularity.fulfilled]: (state, action) => ({
      ...state,
      productPopularity: action.payload || [],
      status: 'succeeded',
    }),
    [getProductPopularity.rejected]: (state) => ({
      ...state,
      productPopularity: [],
      status: 'failed',
    }),
    /* ****************************** */
    /* ADD WARRANTY PLAN TO LINE ITEM */
    /* ****************************** */
    [addWarrantyPlanToLineItem.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [addWarrantyPlanToLineItem.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      status: 'succeeded',
    }),
    [addWarrantyPlanToLineItem.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),
    /* ******************************** */
    /* CHECK LINEITEMS FOR OOS VARIANTS */
    /* ******************************** */
    setShowOutOfStockToaster: (state, action) => ({
      ...state,
      showOutOfStockToaster: action.payload,
    }),
    [checkLineItemsForOOSVariants.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [checkLineItemsForOOSVariants.fulfilled]: (state, action) => ({
      ...state,
      outOfStockLineItems: action.payload,
      status: 'succeeded',
    }),
    [checkLineItemsForOOSVariants.rejected]: (state) => ({
      ...state,
      outOfStockLineItems: [],
      status: 'failed',
    }),
    removeTaxEstimation: (state) => ({
      ...state,
      estimatedTaxZipCode: undefined,
      estimatedTaxAmount: undefined,
      estimatedTaxErrorCode: undefined,
    }),
    addTaxEstimationError: (state, payload) => ({
      ...state,
      estimatedTaxErrorCode: payload.errorCode,
    }),
    removeTaxEstimationError: (state) => ({
      ...state,
      estimatedTaxErrorCode: undefined,
    }),
    [getAbandonedCartToasts.pending]: (state) => ({
      ...state,
      status: 'loading',
      abandonedCartToasts: undefined,
    }),
    [getAbandonedCartToasts.rejected]: (state, action) => ({
      ...state,
      status: 'failed',
      abandonedCartToasts: action.payload,
    }),
    [getAbandonedCartToasts.fulfilled]: (state, action) => ({
      ...state,
      status: 'succeeded',
      abandonedCartToasts: action.payload,
    }),
    /* ******************************** */
    /* GET ABANDONED CARTS COUPON CODES */
    /* ******************************** */
    [getAvailableDiscountCodeForAbandonedCarts.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [getAvailableDiscountCodeForAbandonedCarts.fulfilled]: (state, action) => ({
      ...state,
      discountCodeForAbandonedCarts: action.payload,
      status: 'succeeded',
    }),
    [getAvailableDiscountCodeForAbandonedCarts.rejected]: (state) => ({
      ...state,
      discountCodeForAbandonedCarts: {},
      status: 'failed',
    }),
    [getAvailableDiscountCodeForAbandonedCheckout.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [getAvailableDiscountCodeForAbandonedCheckout.fulfilled]: (
      state,
      action,
    ) => ({
      ...state,
      discountCodeForAbandonedCheckout: action.payload,
      status: 'succeeded',
    }),
    [getAvailableDiscountCodeForAbandonedCheckout.rejected]: (state) => ({
      ...state,
      discountCodeForAbandonedCheckout: {},
      status: 'failed',
    }),
    /* ******************************** */
    /* BUY MORE SAVE MORE PROGRESS TRACKING */
    /* ******************************** */
    setBMSMCurrentQualifiedPercentages: (state, action) => ({
      ...state,
      buyMoreSaveMore: {
        ...state.buyMoreSaveMore,
        currentQualifiedSavings: action.payload
      },
    }),
    setBMSMEstimatedQualifiedPercentages: (state, action) => ({
      ...state,
      buyMoreSaveMore: {
        ...state.buyMoreSaveMore,
        estimatedQualifiedSavings: action.payload
      },
    }),
    setBMSMCurrentTier: (state, action) => ({
      ...state,
      buyMoreSaveMore: {
        ...state.buyMoreSaveMore,
        currentTier: action.payload
      }
    }),
    setBMSMRemaingAmount: (state, action) => ({
      ...state, 
      buyMoreSaveMore: {
        ...state.buyMoreSaveMore,
        remainingAmount: action.payload
      }
    }),
    [getGuaranteeItems.pending]: (state) => ({
      ...state,
      guaranteeItems: [],
      status: 'loading',
    }),
    [getGuaranteeItems.fulfilled]: (state, action) => ({
      ...state,
      guaranteeItems: action.payload,
      status: 'succeeded',
    }),
    [getGuaranteeItems.rejected]: (state) => ({
      ...state,
      guaranteeItems: [],
      status: 'failed',
    }),
  },
});

export default cartSlice.reducer;
