// eslint-disable-next-line import/prefer-default-export
export const debounce = (callback, time) => {
  let debounceTimer;
  return (...arg) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => callback.apply(this, arg), time);
  };
};

export const isCookieSet = (cookieName) => {
  const allCookiesString = document.cookie;
  const allCookiesArray = allCookiesString.split(';');
  return allCookiesArray.find((cookie) => {
    const cookieKey = cookie.trim().split('=');
    return cookieKey.includes(cookieName);
  });
};
export const getCookie = (cookieName) => {
  const allCookiesString = document.cookie;
  const allCookiesArray = allCookiesString.split(';');
  return allCookiesArray
    .map((cookie) => {
      const cookieArray = cookie.trim().split('=');
      return {
        name: cookieArray[0],
        value: cookieArray[1],
      };
    })
    .find(({ name }) => name === cookieName);
};

const clearData = (db) => {
  // open a read/write db transaction, ready for clearing the data
  const transaction = db.transaction(['IdentityIds'], 'readwrite');
  // create an object store on the transaction
  const objectStore = transaction.objectStore('IdentityIds');

  // Make a request to clear all the data out of the object store
  objectStore.clear();
};

export const clearIndexedDB = async () => {
  // Let us open our database
  const DBOpenRequest = window.indexedDB.open('aws:cognito-identity-ids');

  DBOpenRequest.onsuccess = () => {
    // store the result of opening the database in the db variable.
    // This is used a lot below
    const db = DBOpenRequest.result;
    // Clear all the data from the object store
    if (db) {
      clearData(db);
    }
  };

  localStorage.clear();
};

export const getFilenameFromUrl = (url) => {
  const urlObj = new URL(url);
  const parts = urlObj.pathname.split('/');
  return `/${parts[parts.length - 1]}`;
};
