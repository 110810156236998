import request from '../../../client';
import {
  WARRANTY_CUSTOM_LINE_ITEM_TYPE,
} from '../../../utils/common/constants';
import { calculateTotalPriceWithoutDiscount } from '../../../utils';

const SUBSCRIBED_CART_FULFILLED_EVENTS = [
  'cart/addLineItems/fulfilled',
  'cart/changeLineItemQuantity/fulfilled',
  'cart/removeLineItem/fulfilled',
];

const SUBSCRIBED_TEALIUM_CART_PENDING_EVENTS = [
  'cart/removeLineItem/pending',
  'cart/changeLineItemQuantity/pending',
];

const checkConversionTags = async (cart) => {
  const vendors = JSON.parse(localStorage.getItem('purpleConversionVendors'));

  if (vendors) {
    const trackingData =
      cart.conversionTracking ? JSON.parse(cart.conversionTracking) : {};

    Object
      .entries(vendors)
      .forEach((value, key) => { trackingData[key] = value; });

    await request({
      uri: 'carts/tags',
      options: {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(trackingData),
      },
    });
    localStorage.removeItem('purpleConversionVendors');
  }
};

const cartUpdatedEventTrigger = ({ eventName, cart, updatedItems }) => {
  const detail = { event: eventName, cart, updatedItems };
  const cartUpdatedEvent = new CustomEvent('cartUpdated', { detail });
  document.dispatchEvent(cartUpdatedEvent);
};

const cartUpdatedEventTransformer = ({
  actionType,
  cartDataBeforeLineitemChange,
  cart,
  args,
}) => {
  /**
   * In each action the args need to be handled differently
   */

  switch (actionType) {
    case 'cart/addLineItems/fulfilled': {
      let GA4dataToSent = [];
      args?.lineItems.forEach((lineItm) => {
        const { sku, warrantyPlanId, quantity, warrantyPrice } = lineItm;
        const foundItem = cart?.lineItems.find(
          (lineItem) => lineItem?.variant.sku === sku,
        );

        const discountJson = foundItem?.custom?.fields?.discounts_json;
        const itemDiscount =
          discountJson &&
          JSON.parse(discountJson)?.reduce(
            (totalLine, discount) => totalLine + discount.cent_amount,
            0,
          );

        if (foundItem) {
          GA4dataToSent = [
            ...GA4dataToSent,
            {
              id: foundItem.id,
              quantity: quantity || 1,
              sku: foundItem.variant?.sku,
              name: Object.values(foundItem.name)[0],
              price: foundItem?.price?.value?.centAmount,
              product_discount: itemDiscount,
              prodct_price_total: calculateTotalPriceWithoutDiscount(foundItem),
              product_brand: 'Purple',
              product_category: foundItem?.productType?.obj?.name,
              product_subcategory: Object.values(foundItem.name)[0],
            },
          ];
        }
        if (warrantyPlanId) {
          GA4dataToSent = [
            ...GA4dataToSent,
            {
              name: warrantyPlanId,
              price: warrantyPrice,
              product_category: 'Extend Protection',
              product_subcategory: `${Object.values(foundItem.name)[0]} Protection`,
              product_brand: 'Extend',
              quantity: 1,
            },
          ];
        }
      });
      cartUpdatedEventTrigger({
        eventName: 'addItem',
        cart,
        updatedItems: GA4dataToSent,
      });
      break;
    }
    case 'cart/changeLineItemQuantity/fulfilled': {
      const prevQuantity = cartDataBeforeLineitemChange?.lineItems?.filter(
        (item) => item.id === args.lineItemId,
      )?.[0].quantity;

      const updatedQuantity = args.quantity;

      let lineItems;
      let customLineItems;
      let foundItem;

      if (prevQuantity < updatedQuantity) {
        ({ lineItems, customLineItems } = cart);
        const { sku } = args?.lineItems?.[0] || args;
        [foundItem] = cart?.lineItems
          .filter((lineItem) => lineItem?.variant.sku === sku)
          .slice(-1);
      } else {
        ({ lineItems, customLineItems } = cartDataBeforeLineitemChange);
        [foundItem] = lineItems.filter(
          (lineItem) => lineItem.id === args.lineItemId,
        );
      }

      const warrantyLineItems = customLineItems.filter(
        (customLineItem) =>
          customLineItem.custom?.fields?.type ===
            WARRANTY_CUSTOM_LINE_ITEM_TYPE &&
          customLineItem.custom?.fields?.lineItemsIds.includes(args.lineItemId),
      );

      const [warrantyLineItemUpdated] = warrantyLineItems.length
        ? warrantyLineItems
        : [];

      const discountJson = foundItem?.custom?.fields?.discounts_json;
      const itemDiscount =
        discountJson &&
        JSON.parse(discountJson)?.reduce(
          (totalLine, discount) => totalLine + discount.cent_amount,
          0,
        );
      let tealiumData = [];

      if (foundItem) {
        tealiumData = [
          ...tealiumData,
          {
            id: foundItem.id,
            quantity: 1,
            sku: foundItem.variant?.sku,
            name: Object.values(foundItem.name)[0],
            price: foundItem?.price?.value?.centAmount/100,
            product_discount: itemDiscount,
            prodct_price_total:
              (foundItem?.price?.value?.centAmount + itemDiscount) / 100,
            product_brand: 'Purple',
            product_category: foundItem?.productType?.obj?.name,
            product_subcategory: Object.values(foundItem.name)[0],
          },
        ];
      }

      if (warrantyLineItemUpdated) {
        const {
          custom: { fields: selectedWarrantyPlan },
        } = warrantyLineItemUpdated;
        const { warrantyPlanId, warrantyPrice } = selectedWarrantyPlan;

        const itemQuantity =
          warrantyLineItems.length > foundItem.quantity
            ? foundItem.quantity
            : warrantyLineItems.length;

        const warrantyDuration = warrantyPlanId.split('-')[2];
        const warrantyTotalPrice = (warrantyPrice / 100) * itemQuantity;
        tealiumData = [
          ...tealiumData,
          {
            id: warrantyPlanId,
            quantity: 1,
            name: `Extend Protection ${warrantyDuration}`,
            price: warrantyTotalPrice,
            prodct_price_total: warrantyTotalPrice,
            product_brand: 'Extend',
            product_category: `Extend Protection`,
            product_subcategory: `${Object.values(foundItem.name)[0]} Protection`,
          },
        ];
      }

      cartUpdatedEventTrigger({
        eventName: prevQuantity < updatedQuantity ? 'addItem' : 'removeItem',
        cart,
        updatedItems: tealiumData,
      });
      break;
    }
    case 'cart/removeLineItem/fulfilled': {

      const { lineItems, customLineItems } = cartDataBeforeLineitemChange;
      const [lineItemRemoved] = lineItems.filter(
        (lineItem) => lineItem.id === args.lineItemId,
      );
      // handle multiple lineitems with sku and only selected number of item has discount
      // eg: 3 harmony pillows in the cart with discounts for 2 H. pillows.
      const checkIfMultipleQuantity = lineItems.filter(
        (lineItem) =>
          lineItem.variant.sku === lineItemRemoved?.variant.sku &&
          lineItem.id !== args.lineItemId,
      );

      const warrantyLineItems = lineItemRemoved
        ? customLineItems.filter(
          (customLineItem) =>
            customLineItem.custom?.fields?.type ===
            WARRANTY_CUSTOM_LINE_ITEM_TYPE &&
            customLineItem.custom?.fields?.lineItemsIds.includes(
              args.lineItemId,
            ),
        )
        : customLineItems.filter(
          (customLineItem) =>
            customLineItem.id === args.lineItemId,
        );

      const [warrantyLineItemRemoved] = warrantyLineItems.length
        ? warrantyLineItems
        : [];
      
      let tealiumData = [];

      if (lineItemRemoved) {
        const quantity = lineItemRemoved.quantity - (args?.quantity || 0);
        const discountJson = lineItemRemoved?.custom?.fields?.discounts_json;
        const itemDiscount =
          discountJson &&
          JSON.parse(discountJson)?.reduce(
            (totalLine, discount) => totalLine + discount.cent_amount,
            0,
          );
        const [itemName] = Object.values(lineItemRemoved.name);
        const itemPrice = lineItemRemoved?.price?.value?.centAmount;
        const productPrice =
          (!checkIfMultipleQuantity.length
            ? itemPrice
            : itemPrice + itemDiscount) / 100;
        
        tealiumData = [
          ...tealiumData,
          {
            id: lineItemRemoved.id,
            quantity,
            sku: lineItemRemoved.variant?.sku,
            name: itemName,
            price: productPrice,
            prodct_price_total:
            (lineItemRemoved?.price?.value?.centAmount + itemDiscount) / 100,
            product_brand: 'Purple',
            product_category: lineItemRemoved?.productType?.obj?.name,
            product_subcategory: itemName,
            ...(!checkIfMultipleQuantity.length ? {product_discount: itemDiscount} : {}),
          },
        ];
      }
      if (warrantyLineItemRemoved) {
        const {
          custom: { fields: selectedWarrantyPlan },
        } = warrantyLineItemRemoved;
        const { warrantyPlanId, warrantyPrice, lineItemsIds } =
          selectedWarrantyPlan;

        const foundItem = lineItems.find(
          (lineItem) => lineItem.id === lineItemsIds[0],
        );

        const itemQuantity =
          warrantyLineItems.length > foundItem.quantity
            ? foundItem.quantity
            : warrantyLineItems.length;

        const warrantyDuration = warrantyPlanId.split('-')[2];
        const warrantyTotalPrice = (warrantyPrice / 100) * itemQuantity;
        tealiumData = [
          ...tealiumData,
          {
            id: warrantyPlanId,
            quantity: itemQuantity,
            sku:  null,
            name: `Extend Protection ${warrantyDuration}`,
            price:  warrantyTotalPrice,
            prodct_price_total: warrantyTotalPrice,
            product_brand: 'Extend',
            product_category: `Extend Protection`,
          },
        ];
      }

      if (tealiumData?.length) {
        cartUpdatedEventTrigger({
          eventName: 'removeItem',
          cart,
          updatedItems: tealiumData,
        });
      }
      break;
    }
    default:
  }
};

const cartEvents = (store) => {
  // Local state for temporary cart data
  let cartDataBeforeLineitemChange = null;
  return (next) => async (action) => {
    const result = next(action);
    const { cart } = store.getState();

    if (SUBSCRIBED_TEALIUM_CART_PENDING_EVENTS.includes(action.type)) {
      cartDataBeforeLineitemChange = cart;
    }

    if (SUBSCRIBED_CART_FULFILLED_EVENTS.includes(action.type)) {
      checkConversionTags(cart);
      cartUpdatedEventTransformer({
        actionType: action.type,
        cartDataBeforeLineitemChange,
        cart,
        args: action.meta.arg,
      });
    }

    return result;
  };
}


export default cartEvents;
