import ordersMasterService from '../orders/ordersMasterService';

const authorizeSession = async (cartId, isDraft, callback) => {
  try {
    const response = await ordersMasterService.callToMethod(
      {
        cartId,
        isDraft,
        methodName: ordersMasterService.methodsMap.authorizeSession,
        paramsObject: { provider: 'PAYEEZY', withTurnstile: true },
      },
    );

    callback(response);
  } catch (error) {
    throw new Error(error.message);
  }
};

const tokenizationResult = async (clientToken) => {
  try {
    const response = await fetch(
      `${process.env.BASE_API_URL}/payment-hub/tokenization-result`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientToken,
        }),
      },
    );
    if (response.ok) {
      return await response.json();
    }
    throw new Error('Something wrong happened', response.status);
  } catch (error) {
    throw new Error(error.message);
  }
};

const paymentJSService = {
  authorizeSession,
  tokenizationResult,
};

export default paymentJSService;
