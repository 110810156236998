/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": drupalSettings.react.AWS_REGION,
    "aws_cognito_identity_pool_id": drupalSettings.react.AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": drupalSettings.react.AWS_REGION,
    "aws_user_pools_id": drupalSettings.react.AWS_USER_POOLS_ID,
    "aws_user_pools_web_client_id": drupalSettings.react.AWS_USER_POOLS_WEB_CLIENT_ID,
    "oauth": {
        "domain": drupalSettings.react.AWS_OAUTH_DOMAIN,
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "GIVEN_NAME",
        "FAMILY_NAME",
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 10,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};

export default awsmobile;
