import request from '../../client';

const basePath = 'orders/draft-order';

const placeOrder = (orderId, orderData) =>
  request({
    uri: `${basePath}/${orderId}`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(orderData),
    },
  });

const authorizeSession = (orderId, { provider }) =>
  request({
    uri: `${basePath}/${orderId}/authorize-session`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ provider }),
    },
  });

const updateCheckoutSession = (orderId, { provider, checkoutSessionId }) =>
  request({
    uri: `${basePath}/${orderId}/checkout-session`,
    options: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ provider, checkoutSessionId }),
    },
  });

const draftOrdersService = {
  placeOrder,
  authorizeSession,
  updateCheckoutSession,
};

export default draftOrdersService;
