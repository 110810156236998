const publish = ({ subscription, parameters }) => {
  const { pubSub } = window;

  if (subscription && parameters) {
    try {
      return pubSub?.publish?.(subscription, parameters);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error sending message to Drupal Subscription: ${error}`);
      return undefined;
    }
  }
  return undefined;
};

const subscribe = ({ publishedEvent, callback }) => {
  const { pubSub } = window;
  if (publishedEvent) {
    try {
      return pubSub?.subscribe?.(publishedEvent, callback);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error subscription drupal event: ${error}`);
      return undefined;
    }
  }
  return undefined;
};

const unsubscribe = (subscription) => {
  subscription?.unsubscribe();
};

const drupalPubSubService = {
  publish, subscribe, unsubscribe,
};

export default drupalPubSubService;
