import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import paymentJSService from '../../services/paymentJS';
import amazonPayService from '../../services/amazonPay';
import { placeOrder } from '../orders';
import {
  CARD_TOKEN_ERROR,
  CARD_TOKENIZATION_ERROR,
  PAYMENT_BLOCK,
  AMAZON_ERROR,
  GIFT_CARD_ONLY_PAYMENT_ERROR,
} from '../../blocks/Checkout/SteppedCheckout/constants';
import { retryFn, scrollToError } from '../../utils';
import storageUtils from '../../utils/storage';

export const paymentMethods = {
  AFFIRM: 'AFFIRM',
  AMAZON_PAY: 'AMAZON_PAY',
  CREDIT_CARD: 'PAYEEZY_CC',
  PAYPAL: 'PAYPAL',
  SPLIT_IT: 'SPLITIT',
  APPLE_PAY: 'APPLE_PAY',
  GIFT_CARD: 'GIFT_CARD',
  CHARGE_AFTER: 'CHARGE_AFTER',
};

const amazonCheckoutSessionIdStorage = localStorage.getItem(
  'amazonCheckoutSessionId',
);
const paypalOrderIdStorage = localStorage.getItem('paypalOrderId');

const applePaymentDataStorage = localStorage.getItem('applePaymentData');

const kountSessionIdFromLocalStorage = localStorage.getItem('kountSessionId');

export const changePaymentMethod = createAction('payments/changePaymentMethod');
export const savePaymentMethodData = createAction(
  'payments/savePaymentMethodData',
);
export const clearPaymentMethodsData = createAction(
  'payments/clearPaymentMethodsData',
  () => {
    storageUtils.cleanAllPaymentData();
    return {};
  },
);
export const setSplititInstallmentPlanNumber = createAction(
  'payments/setSplititInstallmentPlanNumber',
);
export const paymentJSAuthorizeSessionSuccess = createAction(
  'payments/paymentJSAuthorizeSessionSuccess',
);
export const setPaypalOrderId = createAction('payments/setPaypalOrderId');

export const setAmazonCheckoutSessionId = createAction(
  'payments/setAmazonCheckoutSessionId',
);

export const setApplePaymentData = createAction('payments/setApplePaymentData');

export const setDiscoverCardError = createAction(
  'payments/setDiscoverCardError',
);

export const setKountSessionId = createAction('payments/setKountSessionId');
export const setIsValidatingAddress = createAction(
  'payments/setIsValidatingAddress',
);
export const setAffirmFinancingProgramme = createAction(
  'payments/setAffirmFinancingProgramme',
);

export const initiateAffirmSteppedCheckout = createAsyncThunk(
  'payments/initiateAffirmCheckout',
  async (_, { dispatch, getState }) => {
    const {
      payments: { kountSessionId, paymentMethodData },
    } = getState();
    localStorage.setItem('kountSessionId', kountSessionId);

    dispatch(clearPaymentMethodsData());
    dispatch(savePaymentMethodData({}));
    dispatch(
      placeOrder({
        orderData: {
          paymentMethod: paymentMethods.AFFIRM,
          id: paymentMethodData.affirm.checkout_token,
          sessionId: kountSessionId,
        },
      }),
    );
  },
);

export const initiateChargeAfter = createAsyncThunk(
  'payments/initiateChargeAfter',
  async (_, { dispatch, getState }) => {
    const {
      payments: { kountSessionId, paymentMethodData },
    } = getState();
    localStorage.setItem('kountSessionId', kountSessionId);

    dispatch(clearPaymentMethodsData());
    dispatch(savePaymentMethodData({}));
    dispatch(
      placeOrder({
        orderData: {
          paymentMethod: paymentMethods.CHARGE_AFTER,
          id: paymentMethodData.chargeAfter.confirmationToken,
          sessionId: kountSessionId,
          chargeAfterLender: paymentMethodData.chargeAfter.lenderName,
        },
      }),
    );
  },
);

export const initiatePaypal = createAsyncThunk(
  'payments/initiatePaypal',
  (_, { dispatch, getState }) => {
    const { selectedPaymentMethod, paypalOrderId, kountSessionId } =
      getState().payments;
    dispatch(
      placeOrder({
        orderData: {
          paymentMethod: selectedPaymentMethod,
          id: paypalOrderId,
          sessionId: kountSessionId,
        },
      }),
    );
  },
);

export const initiateCreditCardSteppedCheckout = createAsyncThunk(
  'payments/initiateCreditCardSteppedCheckout',
  async (_, { getState, dispatch }) => {
    const { paymentMethodData, selectedPaymentMethod, kountSessionId } =
      getState().payments;

    dispatch(clearPaymentMethodsData());
    dispatch(
      placeOrder({
        orderData: {
          paymentMethod: selectedPaymentMethod,
          ...paymentMethodData,
          sessionId: kountSessionId,
        },
      }),
    );
  },
);

export const tokenizeCreditCard = createAsyncThunk(
  'payments/tokenizeCreditCard',
  async ({ paymentJSClientToken }, { dispatch }) => {
    try {
      dispatch(clearPaymentMethodsData());
      dispatch(savePaymentMethodData());
      const tokenizationData = await retryFn({
        fn: paymentJSService.tokenizationResult,
        args: paymentJSClientToken,
        retriesLeft:
          process.env.PAYMENTJS_TOKENIZATION_RESULT_QUERY_MAX_ATTEMPTS,
        delayTime: process.env.PAYMENTJS_TOKENIZATION_RESULT_QUERY_DELAY_MS,
      });

      if (tokenizationData?.error === false) {
        dispatch(
          savePaymentMethodData({
            id: tokenizationData.card?.token,
            cardData: {
              brand: tokenizationData.card?.brand,
              expMonth: tokenizationData.card?.exp?.month,
              expYear: tokenizationData.card?.exp?.year?.slice(-2),
              cardHolder: tokenizationData.card?.name,
              last4: tokenizationData.card?.last4,
            },
            clientToken: paymentJSClientToken,
          }),
        );
        dispatch({ type: 'setPaymentStatus', status: 'succeeded' });
      } else {
        dispatch({
          type: 'addError',
          errorBlock: PAYMENT_BLOCK,
          errorCode: CARD_TOKENIZATION_ERROR,
        });
        dispatch({ type: 'setPaymentStatus', status: 'failed' });
        scrollToError(PAYMENT_BLOCK);
      }
    } catch (e) {
      dispatch({
        type: 'addError',
        errorBlock: PAYMENT_BLOCK,
        errorCode: CARD_TOKEN_ERROR,
      });
      dispatch({ type: 'setPaymentStatus', status: 'failed' });
      scrollToError(PAYMENT_BLOCK);
    }
  },
);

export const initiateOnlyGiftCard = createAsyncThunk(
  'payments/initiateOnlyGiftCard',
  async (_, { getState, dispatch }) => {
    const { selectedPaymentMethod, kountSessionId } = getState().payments;
    try {
      dispatch(clearPaymentMethodsData());
      dispatch(savePaymentMethodData({}));
      dispatch(
        placeOrder({
          orderData: {
            paymentMethod: selectedPaymentMethod,
            id: selectedPaymentMethod,
            sessionId: kountSessionId,
          },
        }),
      );
    } catch (e) {
      dispatch({
        type: 'addError',
        errorBlock: PAYMENT_BLOCK,
        errorCode: GIFT_CARD_ONLY_PAYMENT_ERROR,
      });
      scrollToError(PAYMENT_BLOCK);
    }
  },
);

export const initiateApplePay = createAsyncThunk(
  'payments/initiateApplePay',
  async (_, { getState, dispatch }) => {
    const { applePaymentData, selectedPaymentMethod, kountSessionId } =
      getState().payments;
    dispatch(
      placeOrder({
        orderData: {
          paymentMethod: selectedPaymentMethod,
          id: applePaymentData,
          sessionId: kountSessionId,
        },
      }),
    );
  },
);

export const initiateAmazonPay = createAsyncThunk(
  'payments/initiateAmazonPay',
  async (_, { getState, dispatch }) => {
    const {
      payments: { amazonCheckoutSessionId, kountSessionId },
    } = getState();

    const { id, isDraft } = getState().cart;

    try {
      const result = await amazonPayService.paymentIntent({
        cartId: id,
        isDraft,
        checkoutSessionId: amazonCheckoutSessionId,
      });
      localStorage.setItem('kountSessionId', kountSessionId);
      window.location.href = result.amazonPayRedirectUrl;
    } catch (e) {
      dispatch({
        type: 'addError',
        errorBlock: PAYMENT_BLOCK,
        errorCode: AMAZON_ERROR,
      });
      dispatch(setAmazonCheckoutSessionId(null));
      scrollToError(PAYMENT_BLOCK);
    }
  },
);

export const completeOrderSteppedCheckout = createAsyncThunk(
  'order/completeOrderSteppedCheckout',
  async (_, { getState, dispatch }) => {
    const { selectedPaymentMethod } = getState().payments;

    switch (selectedPaymentMethod) {
      case paymentMethods.PAYPAL:
        return dispatch(initiatePaypal());
      case paymentMethods.CREDIT_CARD:
        return dispatch(initiateCreditCardSteppedCheckout());
      case paymentMethods.AFFIRM:
        return dispatch(initiateAffirmSteppedCheckout());
      case paymentMethods.AMAZON_PAY:
        return dispatch(initiateAmazonPay());
      case paymentMethods.APPLE_PAY:
        return dispatch(initiateApplePay());
      case paymentMethods.GIFT_CARD:
        return dispatch(initiateOnlyGiftCard());
      case paymentMethods.CHARGE_AFTER:
        return dispatch(initiateChargeAfter());
      default:
        // eslint-disable-next-line no-console
        console.log('Payment method not implemented.');
    }
    return null;
  },
);

export const paymentJSAuthorizeSession = createAsyncThunk(
  'payments/paymentJSAuthorizeSession',
  async (callback, { getState }) => {
    const { id, isDraft } = getState().cart;

    return paymentJSService.authorizeSession(id, isDraft, callback);
  },
);

const getInitialPaymentMethod = () => {
  if (amazonCheckoutSessionIdStorage) return paymentMethods.AMAZON_PAY;
  if (paypalOrderIdStorage) return paymentMethods.PAYPAL;
  if (applePaymentDataStorage) return paymentMethods.APPLE_PAY;
  return paymentMethods.CREDIT_CARD;
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState: {
    status: 'idle',
    errorCode: undefined,
    selectedPaymentMethod: getInitialPaymentMethod(),
    paymentMethodData: undefined,
    paymentJSClientToken: null,
    paypalOrderId: paypalOrderIdStorage,
    amazonCheckoutSessionId: amazonCheckoutSessionIdStorage,
    splititInstallmentPlanNumber: null,
    validatingFields: false,
    kountSessionId: kountSessionIdFromLocalStorage,
    applePaymentData: applePaymentDataStorage,
    affirmFinancingProgramme: null,
    discoverCardError: false,
  },
  reducers: {
    changePaymentMethod: (state, action) => ({
      ...state,
      selectedPaymentMethod: action.payload,
    }),
    clearPaymentMethodsData: (state) => ({
      ...state,
      amazonCheckoutSessionId: undefined,
      applePaymentData: undefined,
      paymentJSClientToken: undefined,
      paypalOrderId: undefined,
      splititInstallmentPlanNumber: undefined,
    }),
    paymentJSAuthorizeSessionSuccess: (state, action) => ({
      ...state,
      paymentJSClientToken: action.payload,
    }),
    setPaypalOrderId: (state, action) => ({
      ...state,
      paypalOrderId: action.payload,
    }),
    setApplePaymentData: (state, action) => ({
      ...state,
      applePaymentData: action.payload,
    }),
    setAmazonCheckoutSessionId: (state, action) => ({
      ...state,
      amazonCheckoutSessionId: action.payload,
    }),
    setSplititInstallmentPlanNumber: (state, action) => ({
      ...state,
      splititInstallmentPlanNumber: action.payload,
    }),
    setKountSessionId: (state, action) => ({
      ...state,
      kountSessionId: action.payload,
    }),
    savePaymentMethodData: (state, action) => ({
      ...state,
      paymentMethodData: action.payload,
    }),
    setDiscoverCardError: (state, action) => ({
      ...state,
      discoverCardError: action.payload,
    }),
  },
  extraReducers: {
    [completeOrderSteppedCheckout.pending]: (state) => ({
      ...state,
      status: 'loading',
      validatingFields: true,
    }),
    [completeOrderSteppedCheckout.fulfilled]: (state) => ({
      ...state,
      status: 'succeeded',
      validatingFields: false,
    }),
    [completeOrderSteppedCheckout.rejected]: (state) => ({
      ...state,
      status: 'failed',
      validatingFields: false,
    }),
    setPaymentStatus: (state, payload) => ({
      ...state,
      status: payload.status,
    }),
    setIsValidatingAddress: (state, payload) => ({
      ...state,
      validatingFields: payload.isValidatingAddress,
    }),
    setAffirmFinancingProgramme: (state, payload) => ({
      ...state,
      affirmFinancingProgramme: payload.affirmFinancingProgramme,
    }),
  },
});

export default paymentsSlice.reducer;
