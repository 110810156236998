import { GUARANTEES_SORT_ORDER } from '../common/constants';

export const getGuaranteeItemsString = (lineItemsGuarantees) => {
  const productEntityField =
    'field_product.entity:commerce_product.field_product_key';
  const result = lineItemsGuarantees
    .reduce((strAccu, { productKey }, index) => {
      const filterOne = `&filter[${index}][condition][path]=${productEntityField}`;
      const filterTwo = `&filter[${index}][condition][value]=`;
      const filterThree = `&filter[${index}][condition][memberOf]=nid`;
      const finalString = `${filterOne}${filterTwo}${productKey}${filterThree}`;
      return `${strAccu}${finalString}`;
    }, '')
    .replace(/\s/g, '');

  return result;
};

export const parseGuaranteeItemsResponse = (
  lineItems,
  guaranteeItemsResponse,
) =>
  lineItems.map((lineItem) => {
    const productDisplayNode = guaranteeItemsResponse.data.find(
      (node) =>
        guaranteeItemsResponse.included.find(
          (rNode) =>
            rNode.attributes.field_product_key === lineItem.productKey &&
            rNode.id === node.relationships?.field_product?.data?.id,
        ) && node.relationships?.field_guarantee_items?.data?.length,
    );

    return (
      productDisplayNode?.relationships?.field_guarantee_items?.data
        ?.map((node) =>
          guaranteeItemsResponse.included.find((rNode) => rNode.id === node.id),
        )
        ?.sort((a, b) => {
          const indexA = GUARANTEES_SORT_ORDER.findIndex((item) =>
            a.attributes.machine_name.includes(item),
          );
          const indexB = GUARANTEES_SORT_ORDER.findIndex((item) =>
            b.attributes.machine_name.includes(item),
          );
          if (indexA > indexB) {
            return 1;
          }
          if (indexA < indexB) {
            return -1;
          }
          return 0;
        }) ?? []
    );
  });
