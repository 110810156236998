import {
  QM_SEND_EVENT_CONVERSION_FLAGS,
  QM_SEND_EVENT_DEBUG_MESSAGES_BUCKET_ID,
} from './constants';

export const log = ({
  eventId = QM_SEND_EVENT_DEBUG_MESSAGES_BUCKET_ID,
  title,
  message,
  encodeMessageInBase64 = false,
}) => {
  if (window.QuantumMetricAPI) {
    try {
      let messageToSend = message;
      if (encodeMessageInBase64) {
        const buffer = Buffer.from(message, 'utf8');

        messageToSend = buffer.toString('base64');
      }
      window.QuantumMetricAPI.sendEvent(
        eventId,
        QM_SEND_EVENT_CONVERSION_FLAGS,
        `${title}: ${messageToSend}`,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error during call to QM SendEvent', error);
    }
  }
};

export default { log };
