import { storeRecentlyAddedItems } from '../../../slices/cart';

const SUBSCRIBED_CART_FULFILLED_EVENTS = ['cart/addLineItems/fulfilled', 'cart/removeLineItem/fulfilled'];

const addToCartEventTransformer = ({ cart, args, store }) => {
  const recentlyAddedItems = args?.lineItems?.map((lineItem) => {
    const foundItem = cart?.lineItems.find(
      (item) => item?.variant.sku === lineItem.sku,
    );
    return foundItem;
  });
  store.dispatch(storeRecentlyAddedItems(recentlyAddedItems ?? []));
};

const recentAddToCart = (store) => (next) => async (action) => {
  const result = next(action);

  if (SUBSCRIBED_CART_FULFILLED_EVENTS.includes(action.type)) {
    const { cart } = store.getState();
    addToCartEventTransformer({
      cart,
      args: action.meta.arg,
      store,
    });
  }
  return result;
};

export default recentAddToCart;
