import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { MATTRESS_AND_FRAME_OPTION } from '../../blocks/pdp/constants';
import pdpService from '../../services/pdp';

export const setSelectedSKU = createAction('pdp/setSelectedSKU');
export const setSelectedFirstProductVariant = createAction(
  'pdp/setSelectedFirstProductVariant',
);
export const setSelectedWarrantyPlan = createAction(
  'pdp/setSelectedWarrantyPlan',
);
export const setUpdatedWarrantyPlan = createAction(
  'pdp/setUpdatedWarrantyPlan',
);
export const setSelectedSecondSKU = createAction('pdp/setSelectedSecondSKU');
export const changeSelectedQuantity = createAction(
  'pdp/changeSelectedQuantity',
);
export const setSelectedBedFrameAOVSKUs = createAction(
  'pdp/setSelectedBedFrameAOVSKUs',
);
export const setMattressAndFrameOption = createAction(
  'pdp/setMattressAndFrameOption',
);
export const setSelectedComplementaryAOVSKU = createAction(
  'pdp/setSelectedComplementaryAOVSKU',
);
export const setBundlePdpAovSkus = createAction('pdp/setBundlePdpAovSkus');
export const setdetectedWarrantyPlansForPlaceholders = createAction(
  'pdp/setdetectedWarrantyPlansForPlaceholders',
);
export const setSelectedMaterial = createAction('pdp/setSelectedMaterial');
export const setSelectedUpgradeProduct = createAction(
  'pdp/setSelectedUpgradeProduct',
);
export const setTealiumStatus = createAction('pdp/setTealiumStatus');

export const setPDPEstimatedTotal = createAction('pdp/setPDPEstimatedTotal');

export const getProductsSpecsAndMaterials = createAsyncThunk(
  'pdp/getProductsSpecsAndMaterials',
  (productKeys) => pdpService.getProductsSpecsAndMaterials(productKeys),
);

export const pdpSlice = createSlice({
  name: 'pdp',
  initialState: {
    selectedSKU: undefined,
    selectedFirstProductVariant: undefined,
    selectedWarrantyPlan: [],
    selectedSecondSKU: undefined,
    selectedQty: 1,
    selectedBedFrameAOVSKUs: [],
    status: 'idle',
    tealiumStatus: false,
    pdpEstimatedTotal: {},
    mattressAndFrameOption: MATTRESS_AND_FRAME_OPTION,
    selectedComplementaryAOVSKU: undefined,
    selectedBundlePdpAOVSKUs: [],
    detectedWarrantyPlansForPlaceholders: {},
    selectedMaterial: undefined,
    selectedUpgradeProduct: undefined,
    productsSpecsAndMaterials: {},
  },
  reducers: {
    setSelectedSKU: (state, data) => ({
      ...state,
      ...data.payload,
      status: 'success',
    }),
    setSelectedFirstProductVariant: (state, data) => ({
      ...state,
      selectedFirstProductVariant: data.payload.variant,
    }),
    setSelectedWarrantyPlan: (state, data) => ({
      ...state,
      selectedWarrantyPlan: data.payload.warrantyPlan,
    }),
    setSelectedSecondSKU: (state, data) => ({
      ...state,
      selectedSecondSKU: data.payload,
    }),
    changeSelectedQuantity: (state, data) => ({
      ...state,
      ...data.payload,
    }),
    setSelectedBedFrameAOVSKUs: (state, data) => ({
      ...state,
      selectedBedFrameAOVSKUs: data.payload,
    }),
    setMattressAndFrameOption: (state, data) => ({
      ...state,
      ...data.payload,
    }),
    setSelectedComplementaryAOVSKU: (state, data) => ({
      ...state,
      ...data.payload,
    }),
    setBundlePdpAovSkus: (state, data) => ({
      ...state,
      selectedBundlePdpAOVSKUs: data.payload,
    }),
    setdetectedWarrantyPlansForPlaceholders: (state, data) => ({
      ...state,
      detectedWarrantyPlansForPlaceholders: data.payload,
    }),
    setSelectedMaterial: (state, data) => ({
      ...state,
      selectedMaterial: data.payload,
    }),
    setSelectedUpgradeProduct: (state, data) => ({
      ...state,
      selectedUpgradeProduct: data.payload,
    }),
    setTealiumStatus: (state, data) => ({
      ...state,
      ...data.payload,
    }),
    setPDPEstimatedTotal: (state, data) => ({
      ...state,
      pdpEstimatedTotal: data.payload,
    }),
  },
  extraReducers: {
    [getProductsSpecsAndMaterials.fulfilled]: (state, action) => ({
      ...state,
      productsSpecsAndMaterials: action.payload || [],
      status: 'succeeded',
    }),
  },
});

export default pdpSlice.reducer;
