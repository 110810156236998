import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import ordersService from '../../services/orders';
import ordersMasterService from '../../services/orders/ordersMasterService';
import { log } from '../../services/quantumMetric';

import {
  PAYMENT_BLOCK,
  DELIVERY_BLOCK,
  SHIPPING_INFO_STEP,
} from '../../blocks/Checkout/SteppedCheckout/constants';

import { retryFn, isDeliveryMethodError } from '../../utils';

export const placeOrder = createAsyncThunk(
  'order/placeOrder',
  async ({ orderData }, { getState, rejectWithValue }) => {
    try {
      log({
        title: 'PlaceOrder Thunk called with:',
        message: JSON.stringify(orderData),
        encodeMessageInBase64: true,
      });
      const cartId = getState().cart.id;
      log({
        title: 'PlaceOrder Thunk cartId:',
        message: cartId,
      });

      // eslint-disable-next-line prefer-destructuring
      const isDraft = getState().cart.isDraft;
      log({
        title: 'PlaceOrder Thunk isDraft:',
        message: isDraft,
      });

      return await retryFn({
        fn: ordersMasterService.callToMethod,
        args: {
          cartId,
          isDraft,
          methodName: ordersMasterService.methodsMap.placeOrder,
          paramsObject: orderData,
        },
        retriesLeft:
          process.env.PAYMENTJS_TOKENIZATION_RESULT_QUERY_MAX_ATTEMPTS,
          delayTime: process.env.PAYMENTJS_TOKENIZATION_RESULT_QUERY_DELAY_MS,
      });
    } catch (error) {
      log({
        title: 'CheckoutError:',
        message: `placeOrder - ${JSON.stringify(error, Object.getOwnPropertyNames(error))}`,
        encodeMessageInBase64: true,
      });
      // TODO DEPRECATED
      document.body.classList.remove('complete-order-click');

      const errorCode = error.apiErrors?.[0]?.code;

      const errorBlock = isDeliveryMethodError(errorCode)
        ? DELIVERY_BLOCK
        : PAYMENT_BLOCK;

      return rejectWithValue({
        errorCode: errorCode || error.message,
        errorBlock,
      });
    }
  },
);

export const getOrder = createAsyncThunk('order/getOrder', async (orderId) =>
  ordersService.getOrder(orderId),
);

export const removeError = createAction('removeError');
export const addError = createAction('addError');
export const setStatus = createAction('order/setStatus');
export const setCurrentStep = createAction('setCurrentStep');
export const setEditingStep = createAction('setEditingStep');

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    status: 'idle',
    errorCode: undefined,
    currentStep: SHIPPING_INFO_STEP,
    editingStep: undefined,
  },
  reducers: {},
  extraReducers: {
    [placeOrder.pending]: (state) => ({
      ...state,
      errorCode: undefined,
      errorBlock: undefined,
      status: 'loading',
    }),
    [placeOrder.fulfilled]: (state, action) => ({
      ...state,
      errorCode: undefined,
      errorBlock: undefined,
      ...action.payload,
      status: 'succeeded',
    }),
    [placeOrder.rejected]: (state, action) => ({
      ...state,
      errorCode: action.payload.errorCode,
      errorBlock: action.payload.errorBlock,
      status: 'failed',
    }),
    [getOrder.pending]: (state) => ({
      ...state,
      errorCode: undefined,
      status: 'loading',
    }),
    [getOrder.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      status: 'succeeded',
    }),
    [getOrder.rejected]: (state, payload) => ({
      ...state,
      errorCode: payload.error.message,
      status: 'failed',
    }),
    removeError: (state) => ({
      ...state,
      errorCode: undefined,
      errorBlock: undefined,
    }),
    addError: (state, payload) => ({
      ...state,
      errorBlock: payload.errorBlock,
      errorCode: payload.errorCode,
    }),
    setStatus: (state, payload) => ({
      ...state,
      status: payload.status,
    }),
    setCurrentStep: (state, payload) => ({
      ...state,
      currentStep: payload.value,
    }),
    setEditingStep: (state, payload) => ({
      ...state,
      editingStep: payload.value,
    }),
  },
});

export default orderSlice.reducer;
