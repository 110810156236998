export const MATTRESS_ONLY_OPTION = '1';
export const MATTRESS_AND_FRAME_OPTION = '2';

export const SIZE_ATTRIBUTES = [
  'size',
  'pillow-size',
  'bedding-size',
  'apparel-size',
];
export const DEPTH_ATTRIBUTES = ['depth'];
export const HEIGHT_ATTRIBUTES = ['height', 'pillow-height'];
export const COLOR_ATTRIBUTES = ['color', 'bedframe-color'];
export const GENDER_ATTRIBUTES = ['gender'];
export const QUANTITY_ATTRIBUTES = ['quantity'];
export const FEEL_ATTRIBUTES = ['feel'];
export const ProductCardType = {
  NORMAL: 'normal',
  ALTERNATIVE: 'alternative',
};

export const DRUPAL_NODE = {
  PRODUCT_SELECTOR: 'paragraph--product_selector',
  PRODUCT_MATERIAL: 'taxonomy_term--product_materials',
  LINK_MODAL: 'paragraph--link_modal',
  FILE: 'file--file',
  BYNDER: 'media--bynder_image',
  DISPLAY: 'taxonomy_term--display',
  BADGE: 'taxonomy_term--badges',
  COMPARE_MODAL: 'paragraph--link_compare_modal',
  TAXONOMY_DISPLAY_TYPE: 'taxonomy_term--display',
  TAXONOMY_BADGE_TYPE: 'taxonomy_term--badges',
  TAXONOMY_NON_SALE_BADGE: 'purple_promotion',
};

export const TEALIUM_ATTRIBUTES_INCLUDED_LIST = [
  'gender',
  'pillow-height',
  'feel',
];
export const TEALIUM_SIZE_ATTRIBUTES = [...SIZE_ATTRIBUTES, 'title'];

