import { Auth } from 'aws-amplify';

const authService = {
  // ***********************************************************
  //                   New Account Creation
  // ***********************************************************
  createNewAccount: async ({
    email,
    password,
    givenName,
    familyName,
    phoneNumber,
  }) => {
    const params = {
      username: email,
      password,
      attributes: {
        email,
        phone_number: phoneNumber,
        given_name: givenName,
        family_name: familyName,
      },
    };
    const response = await Auth.signUp(params);
    return response;
  },

  // ***********************************************************
  //                    Confirm User Account
  // ***********************************************************
  confirmNewAccount: async ({ username, confirmationCode }) => {
    const response = await Auth.confirmSignUp(username, confirmationCode);
    return response;
  },
  // ***********************************************************
  //                   Current User Information
  // ***********************************************************
  currentUserInfo: async () => {
    const response = await Auth.currentUserInfo();
    return response;
  },

  // ***********************************************************
  //                     Authorize
  // ***********************************************************
  authorize: async ({ username, password }) => {
    const response = await Auth.signIn(username, password);
    return response;
  },

  // ***********************************************************
  //                  Global Logout
  // ***********************************************************
  globalSignout: async () => {
    const response = Auth.signOut({ global: true });
    return response;
  },

  // ***********************************************************
  //               Resend Account Confirmation Code
  // ***********************************************************
  resendConfirmationCode: async ({ username }) => {
    const response = await Auth.resendSignUp(username);
    return response;
  },

  // ***********************************************************
  //               Reset Password - Get Code
  // ***********************************************************
  resetPassword: async ({ email }) => {
    const response = await Auth.forgotPassword(email);
    return response;
  },

  // ***********************************************************
  //               Reset Password - Submit Form
  // ***********************************************************
  resetPasswordSubmit: async ({ username, code, password }) => {
    const response = await Auth.forgotPasswordSubmit(
      username, code, password,
    );
    return response;
  },
};

export default authService;
