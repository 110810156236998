import { configureStore } from '@reduxjs/toolkit';
import cartReducer from '../slices/cart';
import shippingMethodsReducer from '../slices/shippingMethods';
import aovReducer from '../slices/aovs';
import cartEvents from './middlewares/cart-events';
import cartChangesToDrupal from './middlewares/cartChangesToDrupal';
import orderReducer from '../slices/orders';
import paymentsReducer from '../slices/payments';
import billingAddressReducer from '../slices/billingAddress';
import shippingAddressReducer from '../slices/shippingAddress';
import pdpReducer from '../slices/pdp';
import accountsReducer from '../slices/accounts';
import bundlePdpReducer from '../slices/bundlePdp';
import navigationReducer from '../slices/navigation';
import tealiumEvents from './middlewares/tealium-events/tealium-events';
import recentAddToCart from './middlewares/recent-add-to-cart';

const store = configureStore({
  reducer: {
    cart: cartReducer,
    shippingMethods: shippingMethodsReducer,
    aov: aovReducer,
    order: orderReducer,
    payments: paymentsReducer,
    billingAddress: billingAddressReducer,
    shippingAddress: shippingAddressReducer,
    pdp: pdpReducer,
    accounts: accountsReducer,
    bundlePdp: bundlePdpReducer,
    navigation: navigationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(cartEvents)
      .concat(tealiumEvents)
      .concat(cartChangesToDrupal)
      .concat(recentAddToCart),
});

window.readOnlyPurpleStore = store;

export default store;
