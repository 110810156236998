import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import cartService from '../../services/cart';

export const getCartAOVs = createAsyncThunk(
  'cart/getCartAOVs',
  async () => cartService.getCartAOVs(),
);

export const setEstimatedAOVTotalWithDiscount = createAction('setEstimatedAOVTotalWithDiscount');

export const aovSlice = createSlice({
  name: 'cart',
  initialState: {
    status: 'idle',
    estimatedAOVTotalWithDiscount: {
      type: "centPrecision",
      currencyCode: "USD",
      centAmount: 0,
      fractionDigits: 2,
    }
  },
  reducers: {},
  extraReducers: {
    [getCartAOVs.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [getCartAOVs.fulfilled]: (state, action) => {
      const { payload } = action;
      if (!payload.addons) {
        return {
          ...state,
          addons: null,
          aovTitle: null,
          status: 'succeeded',
        };
      }
      return {
        ...state,
        ...payload,
        status: 'succeeded',
      };
    },
    [getCartAOVs.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),
    setEstimatedAOVTotalWithDiscount: (state, action) => ({
      ...state,
      estimatedAOVTotalWithDiscount: action.payload,
    }),
  },
});

export default aovSlice.reducer;
