import request from '../../client';

const basePath = 'carts/draft-cart';

const getDraftCart = (cartId) =>
  request({
    uri: `${basePath}/${cartId}`,
    options: {
      method: 'GET',
    },
  });

const setShippingAddress = (cartId, { shippingAddress }) =>
  request({
    uri: `${basePath}/${cartId}/shipping-address`,
    options: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(shippingAddress),
    },
  });

const setBillingAddress = (cartId, { billingAddress }) =>
  request({
    uri: `${basePath}/${cartId}/billing-address`,
    options: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...billingAddress,
        streetAddress:
          billingAddress.streetAddress || billingAddress.streetName,
      }),
    },
  });

const getShippingMethods = (cartId) =>
  request({
    uri: `${basePath}/${cartId}/shipping-methods`,
    options: {
      method: 'GET',
    },
  });

const setShippingMethods = (cartId, { selectedShippingMethods }) =>
  request({
    uri: `${basePath}/${cartId}/shipping-methods`,
    options: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(selectedShippingMethods),
    },
  });

const addGiftCard = (cartId, { giftCardNumber, securityCode }) =>
  request({
    uri: `${basePath}/${cartId}/gift-card`,
    options: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ giftCardNumber, securityCode }),
    },
  });

const removeGiftCard = (cartId, { giftCardCode }) =>
  request({
    uri: `${basePath}/${cartId}/gift-card/${giftCardCode}`,
    options: {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    },
  });

const draftCartService = {
  getDraftCart,
  setBillingAddress,
  setShippingAddress,
  getShippingMethods,
  setShippingMethods,
  addGiftCard,
  removeGiftCard,
};

export default draftCartService;
