import { getCookie } from './common/common';

const DEFAULT_PROMO_VALUE = 'control';

const getABTestingGroup = (skipPromoName) => {
  const abTestingCookieName = window.drupalSettings?.pur_pt?.ptName;

  if (abTestingCookieName &&
    (skipPromoName || process.env.PROMO_AB_COOKIE !== abTestingCookieName)) {
    const cookiesArray = document.cookie.split(';');
    const regex = RegExp(abTestingCookieName);
    const cookie = cookiesArray.find((paramCookie) => regex.test(paramCookie));

    if (cookie) {
      const indexOfEqualSign = cookie.indexOf('=');
      // Add one to skip the equals sign
      const groupValue = cookie.slice(indexOfEqualSign + 1);

      return groupValue;
    }
  }

  return undefined;
};

const filterAovsByTestId = (paramAovs) => paramAovs?.filter((aov) => {
  const promoABCookieValue = getCookie(process.env.PROMO_AB_COOKIE)?.value;
  return (!promoABCookieValue && aov.promoTest?.toLowerCase() === DEFAULT_PROMO_VALUE) ||
    aov.promoTest === promoABCookieValue || !aov.promoTest;
});

const mergeAvos = (aovs = []) => aovs.reduce((accum, item) => {
  const result = [...accum];
  const aovSku = item.targetSku;
  const itemIndex = result.findIndex((aov) => aov?.targetSku === aovSku);
  if (itemIndex >= 0) {
    result[itemIndex] = { ...accum[itemIndex], ...item };
  } else {
    result.push(item);
  }

  return result;
}, []);

const fetchAllAvailableTest = () => {
  const { abGroup } = window.drupalSettings?.pur_pt || {};
  if (abGroup) {
    return Object.keys(abGroup)
      .map((testingGroup) => getCookie(testingGroup))
      .find(Boolean);
  }
  return null;
};
fetchAllAvailableTest();

export default {
  getABTestingGroup,
  filterAovsByTestId,
  formatMergedAovs: mergeAvos,
  DEFAULT_PROMO_VALUE,
  fetchAllAvailableTest,
};
