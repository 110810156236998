import { log } from '../quantumMetric';

const execute = async (callback) => {
  try {
    // eslint-disable-next-line no-undef
    await turnstile.remove();
    // eslint-disable-next-line no-undef
    const widgetId = await turnstile.render(
      `#${process.env.TURNSTILE_DOM_ELEMENT_ID}`,
      {
        sitekey: process.env.TURNSTILE_SITE_KEY,
        callback: (token) => {
          log({ title: 'Turnstile', message: `Challenge Success ${token}` });
          callback();
        },
        'error-callback': (error) => {
          log({ title: 'Turnstile', message: `Challenge Error ${error}` });

          // returns false so Turnstile continues with the processing and not marks the error as handled
          return false;
        },
        'unsupported-callback': () => {
          log({ title: 'Turnstile', message: 'Unsupported Browser' });
        },
        'before-interactive-callback': () => {
          log({ title: 'Turnstile', message: 'Before Interactive Mode' });
        },
        'after-interactive-callback': () => {
          log({ title: 'Turnstile', message: 'After Interactive Mode' });
        },
        appearance: 'interaction-only',
        theme: 'light',
        size: 'normal',
      },
    );
    log({ title: 'Turnstile', message: `widgetId ${widgetId}` });
  } catch (error) {
    log({
      title: 'Turnstile',
      message: `SDK error ${error?.message || 'Unknown error'}`,
    });
  }
};

const getResponse = async () => {
  // eslint-disable-next-line no-undef
  const isExpired = turnstile.isExpired();
  if (isExpired) {
    // eslint-disable-next-line no-undef
    await turnstile.refresh();
  }
  // eslint-disable-next-line no-undef
  const response = await turnstile.getResponse();
  return response;
};

export default { execute, getResponse };
