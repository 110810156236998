import request from '../../client';
import { log } from '../quantumMetric';
import turnstileService from '../turnstile';

const getOrder = (orderId) =>
  request({
    uri: `orders/${orderId}`,
    options: {
      method: 'GET',
    },
  });

const placeOrder = (orderData) => {
  log({
    title: 'PlaceOrder data:',
    message: JSON.stringify(orderData),
    encodeMessageInBase64: true,
  });
  return request({
    uri: 'orders/',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(orderData),
    },
  });
};

const authorizeSession = async ({ provider, withTurnstile = false }) => {
  const turnstileResponse = withTurnstile
    ? await turnstileService.getResponse()
    : undefined;

  return request({
    uri: 'orders/authorize-session',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(withTurnstile ? { 'Turnstile-Response': turnstileResponse } : {}),
      },
      body: JSON.stringify({ provider }),
    },
  });
};

const updateCheckoutSession = ({ provider, checkoutSessionId }) =>
  request({
    uri: 'orders/checkout-session',
    options: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ provider, checkoutSessionId }),
    },
  });

const ordersService = {
  getOrder,
  placeOrder,
  authorizeSession,
  updateCheckoutSession,
};

export default ordersService;
