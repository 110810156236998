/* eslint-disable import/no-cycle */
import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Amplify } from 'aws-amplify';
import accountsService from '../../services/customer-accounts/accounts';
import AuthService from '../../services/cognito/auth';
import { formatPhone, removeUScountryCode } from '../../utils';
import awsconfig from '../../aws-exports';

export const setIsAlmostDone = createAction('accounts/setIsAlmostDone');
export const setUnverifiedEmail = createAction('accounts/setUnverifiedEmail');
export const setIsUserNotConfirmed = createAction(
  'accounts/setIsUserNotConfirmed',
);
export const setShowLoginModal = createAction('accounts/setShowLoginModal');
export const setShowSignupModal = createAction('accounts/setShowSignupModal');
export const setCustomerAddress = createAction('accounts/setCustomerAddress');
export const setIsResendEmailSubmitted = createAction(
  'accounts/setIsResendEmailSubmitted',
);
export const setShowResetModalByDefault = createAction(
  'accounts/setShowResetModalByDefault',
);
export const setLoadResetPasswordComponent = createAction(
  'accounts/setLoadResetPasswordComponent',
);
export const setResetPasswordSuccess = createAction(
  'accounts/setResetPasswordSuccess',
);
export const updateAddresses = createAction('accounts/updateAddresses');
export const removeAddress = createAction('accounts/removeAddress');

export const getActiveCustomer = createAsyncThunk(
  'accounts/getActiveCustomer',
  async (customerId) => {
    try {
      Amplify.configure(awsconfig);
      const customerInfo = await accountsService.getCTCustomer(customerId);
      const currentUser = await AuthService.currentUserInfo();
      const { attributes } = currentUser;

      // eslint-disable-next-line camelcase
      const { phone_number } = attributes;
      const phoneNumber = formatPhone(removeUScountryCode(phone_number));

      let customerAddress = {
        firstName: customerInfo.firstName,
        lastName: customerInfo.lastName,
        postalCode: '',
        city: '',
        state: '',
        country: '',
        email: customerInfo.email,
        streetName: '',
        phone: phoneNumber,
      };

      if (customerInfo.addresses?.length) {
        const primaryAddress =
          customerInfo.addresses.find(
            (addr) => addr.isDefaultShippingAddress,
          ) ?? customerInfo.addresses[0];

        if (primaryAddress) {
          customerAddress = {
            ...customerAddress,
            ...primaryAddress,
            email: customerInfo.email,
            phone: formatPhone(removeUScountryCode(primaryAddress.phone)),
            additionalStreetInfo: primaryAddress.apartment || '',
          };
        }
      }

      return { customerAddress, addresses: customerInfo.addresses };
    } catch (error) {
      return error;
    }
  },
);
export const setCustomerId = createAsyncThunk(
  'accounts/setCustomerId',
  async (customerId, { dispatch }) => {
    if (customerId) {
      dispatch(getActiveCustomer(customerId));
    }
    return { customerId };
  },
);

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    isAlmostDone: false,
    unverifiedEmail: '',
    isUserNotConfirmed: false,
    showLoginModal: false,
    showSignupModal: false,
    customerId: null,
    customerAddress: {},
    status: 'idle',
    isResendEmailSubmitted: false,
    showResetModalByDefault: false,
    loadResetPasswordComponent: false,
    resetPasswordSuccess: false,
    user: {
      id: '',
      profile: {},
      addresses: [],
      payments: [],
      orders: [],
      currentOrder: {},
      message: '',
      marketing: {
        emails: true,
      },
    },
    isDesktop: window.outerWidth > 768,
    userNotConfirmed: false,
    userAddressesFetched: false,
  },
  reducers: {
    setIsAlmostDone: (state, data) => ({
      ...state,
      ...data.payload,
    }),
    setUnverifiedEmail: (state, data) => ({
      ...state,
      ...data.payload,
    }),
    setIsUserNotConfirmed: (state, data) => ({
      ...state,
      ...data.payload,
    }),
    setShowLoginModal: (state, data) => ({
      ...state,
      ...data.payload,
    }),
    setShowSignupModal: (state, data) => ({
      ...state,
      ...data.payload,
    }),
    setCustomerId: (state, data) => ({
      ...state,
      customerId: data.payload,
    }),
    setCustomerAddress: (state, data) => ({
      ...state,
      customerAddress: data.payload,
    }),
    setIsResendEmailSubmitted: (state, data) => ({
      ...state,
      ...data.payload,
    }),
    setShowResetModalByDefault: (state, data) => ({
      ...state,
      ...data.payload,
    }),
    setLoadResetPasswordComponent: (state, data) => ({
      ...state,
      ...data.payload,
    }),
    setResetPasswordSuccess: (state, data) => ({
      ...state,
      ...data.payload,
    }),
    setUserProfile: (state, data) => ({
      ...state,
      user: {
        ...state?.user,
        profile: data.payload,
      },
    }),
    setUserID: (state, data) => ({
      ...state,
      user: {
        ...state?.user,
        id: data.payload,
      },
    }),
    setUserMessage: (state, data) => ({
      ...state,
      user: {
        ...state?.user,
        message: data.payload,
      },
    }),
    changeName: (state, data) => ({
      ...state,
      user: {
        ...state?.user,
        profile: {
          ...state?.user.profile,
          given_name: data.payload.given_name,
          family_name: data.payload.family_name,
        },
      },
    }),
    updateEmailPreference: (state, data) => ({
      ...state,
      user: {
        ...state?.user,
        marketing: {
          emails: data.payload.checked,
        },
      },
    }),
    updatePhoneNumber: (state, data) => ({
      ...state,
      user: {
        ...state?.user,
        profile: {
          ...state?.user.profile,
          phone_number: data.payload,
        },
      },
    }),
    updateEmail: (state, data) => ({
      ...state,
      user: {
        ...state?.user,
        profile: {
          ...state?.user.profile,
          email: data.payload,
        },
      },
    }),
    updateOrders: (state, data) => ({
      ...state,
      user: {
        ...state?.user,
        orders: data.payload,
      },
    }),
    updateCurrentOrder: (state, data) => ({
      ...state,
      user: {
        ...state?.user,
        currentOrder: data.payload,
      },
    }),
    updatePayments: (state, data) => ({
      ...state,
      user: {
        ...state?.user,
        payments: data.payload,
      },
    }),
    updateAddresses: (state, data) => ({
      ...state,
      user: {
        ...state?.user,
        addresses: data.payload,
      },
    }),
    updateIsDesktop: (state, data) => ({
      ...state,
      isDesktop: data.payload,
    }),
    updateUserNotConfirmed: (state, data) => ({
      ...state,
      userNotConfirmed: data.payload,
    }),
    setUserAddressesFetched: (state, data) => ({
      ...state,
      userAddressesFetched: data.payload,
    }),
    removeAddress: (state, data) => ({
      ...state,
      user: {
        ...state?.user,
        addresses: state?.user?.addresses?.filter(
          (address) => address.id !== data.payload,
        ),
      },
    }),
  },
  extraReducers: {
    [getActiveCustomer.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [getActiveCustomer.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      status: 'succeeded',
    }),
    [getActiveCustomer.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),
    [setCustomerId.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export default accountsSlice.reducer;
