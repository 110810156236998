import getToken from '../services/cognito';
import ABTesting from '../utils/ab-testing';
import { getCookie } from '../utils/common/common';

const request = async ({
  uri,
  options,
  retries = 0,
  host = process.env.BASE_API_URL,
  authToken = true
}) => {
  let token = localStorage.getItem('access_token');
  if (!token) {
    token = await getToken();
    localStorage.setItem('access_token', token);
  }

  const abTestingGroup = ABTesting.getABTestingGroup();
  const abPromoTestGroup = getCookie(process.env.PROMO_AB_COOKIE)?.value;

  const abTestingHeader = abTestingGroup
    ? { 'x-ab-testing': abTestingGroup }
    : {};

  const abPromoTestingHeader = abPromoTestGroup
    ? { 'x-promo-test': abPromoTestGroup }
    : {};

  const headers = {
    ...(authToken && { Authorization: `Bearer ${token}` }),
    ...abTestingHeader,
    ...abPromoTestingHeader,
  };

  const response = await fetch(`${host}/${uri}`, {
    ...options,
    headers: options.headers ? {
      ...options.headers,
      ...headers,
    } : headers,
  });

  if (response.ok) {
    return response.json();
  }
  if (response.status === 403) {
    if (retries > 0) {
      throw new Error('could not retrieve the token');
    }
    const newToken = await getToken();
    localStorage.setItem('access_token', newToken);
    return request({ uri, options, retries: retries + 1 });
  }
  const error = await response.json();

  const errorInstance = new Error(error?.errors?.[0]?.code);
  errorInstance.metadata = error?.errors?.[0]?.meta;
  errorInstance.apiErrors = error.errors;

  throw errorInstance;
};

export default request;
