import { createAction, createSlice } from '@reduxjs/toolkit';

export const setPaypalShippingAddressDraft = createAction(
  'shippingAddress/setPaypalShippingAddressDraft',
);

export const setNeedsToCheckPaypalShippingAddressDraft = createAction(
  'shippingAddress/setNeedsToCheckPaypalShippingAddressDraft',
);

export const setNeedsToCheckCustomerAccountsShippingAddressDraft = createAction(
  'shippingAddress/setNeedsToCheckCustomerAccountsShippingAddressDraft',
);

export const setAmazonPayShippingAddressDraft = createAction(
  'shippingAddress/setAmazonPayShippingAddressDraft',
);

export const setNeedsToCheckAmazonPayShippingAddressDraft = createAction(
  'shippingAddress/setNeedsToCheckAmazonPayShippingAddressDraft',
);

export const setApplePayShippingAddressDraft = createAction(
  'shippingAddress/setApplePayShippingAddressDraft',
);

export const setNeedsToCheckApplePayShippingAddressDraft = createAction(
  'shippingAddress/setNeedsToCheckApplePayShippingAddressDraft',
);

export const setNeedsToValidateFieldsOfCustomerAccountAddressDraft = createAction(
  'shippingAddress/setNeedsToValidateFieldsOfCustomerAccountAddressDraft',
);

export const shippingAddressSlice = createSlice({
  name: 'shippingAddress',
  initialState: {
    paypalShippingAddressDraft: undefined,
    needsToCheckPaypalShippingAddressDraft: false,
    amazonPayShippingAddressDraft: undefined,
    needsToCheckAmazonPayShippingAddressDraft: false,
    applePayShippingAddressDraft: undefined,
    needsToCheckApplePayShippingAddressDraft: false,
    needsToCheckCustomerAccountsShippingAddressDraft: false,
    needsToValidateFieldsOfCustomerAccountAddressDraft: false,
  },
  reducers: {
    setPaypalShippingAddressDraft: (state, action) => ({
      ...state,
      paypalShippingAddressDraft: action.payload,
    }),
    setNeedsToCheckPaypalShippingAddressDraft: (state, action) => ({
      ...state,
      needsToCheckPaypalShippingAddressDraft: action.payload,
    }),
    setNeedsToCheckCustomerAccountsShippingAddressDraft: (state, action) => ({
      ...state,
      needsToCheckCustomerAccountsShippingAddressDraft: action.payload,
    }),
    setAmazonPayShippingAddressDraft: (state, action) => ({
      ...state,
      amazonPayShippingAddressDraft: action.payload,
    }),
    setNeedsToCheckAmazonPayShippingAddressDraft: (state, action) => ({
      ...state,
      needsToCheckAmazonPayShippingAddressDraft: action.payload,
    }),
    setApplePayShippingAddressDraft: (state, action) => ({
      ...state,
      applePayShippingAddressDraft: action.payload,
    }),
    setNeedsToCheckApplePayShippingAddressDraft: (state, action) => ({
      ...state,
      needsToCheckApplePayShippingAddressDraft: action.payload,
    }),
    setNeedsToValidateFieldsOfCustomerAccountAddressDraft: (state, action) => ({
      ...state,
      needsToValidateFieldsOfCustomerAccountAddressDraft: action.payload,
    }),
  },
  extraReducers: {},
});

export default shippingAddressSlice.reducer;
