import { getDiscountPercent, stringToCentAmount } from '../index';
import ABTesting from '../ab-testing';

export const parseDrupalSettingsVariants = (variants) => {
  const abTestingGroup = ABTesting.getABTestingGroup(true);

  return variants?.map((variant) => {
    const abTestingObject = variant.pt?.[abTestingGroup];

      const priceToParse = abTestingObject
        ? abTestingObject.price
        : variant.price;
      const discountToParse = abTestingObject
        ? abTestingObject.discount
        : variant.discount;

      const discount = stringToCentAmount({ value: discountToParse });
      const basePrice = stringToCentAmount({ value: priceToParse });
      const discountedPrice = {
        ...basePrice,
        centAmount: basePrice.centAmount - discount.centAmount,
      };
    const promoText = abTestingObject
      ? `<p>${abTestingObject?.promo_text}</p>`
      : null;
      return {
        ...variant,
        pt: abTestingObject,
        price: discountedPrice,
        discount,
        promoText,
      };
  });
};

export const getAttributeValue = (attributes = [], name) =>
  attributes.find((a) => a.name === name)?.value ?? '';

export const getAttributeKey = (attributes = [], name) => attributes.find((a) => a.name === name)?.key ?? '';

export const findVariantByAttributeValues = ({
  variants,
  attributeValues,
  returnPartialMatchingIfFullMatchIsNotFound = false,
}) => {
  const foundVariant = variants?.find(({ attributes }) =>
    attributes.every((attr) => {
      const foundAttribute = attributeValues.find(
        (mainProductAttr) => mainProductAttr?.name === attr.name,
      );
      return !foundAttribute || foundAttribute.key === attr.key;
    }),
  );
  if (returnPartialMatchingIfFullMatchIsNotFound && !foundVariant) {
    const foundVariantPartialMatching = variants
      ?.filter(({ attributes }) =>
        attributes.some((attr) => {
          const foundAttribute = attributeValues.find(
            (mainProductAttr) => mainProductAttr?.name === attr.name,
          );
          return !foundAttribute || foundAttribute.key === attr.key;
        }),
      )
      ?.map((variant) => ({
        variant,
        matchingAttributesCount:
          variant.attributes.filter((attr) => {
            const foundAttribute = attributeValues.find(
              (mainProductAttr) => mainProductAttr?.name === attr.name,
            );
            return !foundAttribute || foundAttribute.key === attr.key;
          })?.length ?? 0,
      }))
      ?.sort((partialMatchingVariant1, partialMatchingVariant2) =>
        partialMatchingVariant1.matchingAttributesCount >
        partialMatchingVariant2.matchingAttributesCount
          ? -1
          : 1,
      )?.[0]?.variant;
    return foundVariantPartialMatching;
  }
  return foundVariant;
};

export const findVariantBySku = ({
  variants = [],
  sku,
  returnFirstVariantIfNotFound = false,
}) =>
  variants.find((variant) => variant.sku === sku) ??
  (returnFirstVariantIfNotFound ? variants[0] : undefined);

export const findVariantAttributeByName = (variant, name) =>
  variant?.attributes?.find((attr) => attr.name === name);

export const filterVariantsByAttributeNameAndKey = ({ variants, name, key }) =>
  variants?.filter(({ attributes }) =>
    attributes.find((attr) => attr.name === name && attr.key === key),
  );

export const getVariantsWithPercentDiscount = (variants, percentage) =>
  variants.map(({ price, discount, ...variant }) => {
    const absolutePrice = price.centAmount + (discount?.centAmount ?? 0);
    const absoluteDiscount =
      100 * Math.ceil((absolutePrice * percentage) / 10000);

    return {
      ...variant,
      price: {
        ...price,
        centAmount: absolutePrice - absoluteDiscount,
      },
      discount: {
        ...price,
        centAmount: absoluteDiscount,
      },
    };
  });

export const getHighestDiscountedVariant = (variants) =>
  variants?.reduce(
    (maxDiscounted, currVariant) => {
      const maxDiscountedPercent = getDiscountPercent(
        maxDiscounted.price.centAmount,
        maxDiscounted.discount?.centAmount,
      );

      const currVariantDiscountPercent = getDiscountPercent(
        currVariant.price.centAmount,
        currVariant.discount?.centAmount,
      );

      return currVariantDiscountPercent > maxDiscountedPercent
        ? currVariant
        : maxDiscounted;
    },
    { price: { centAmount: 0 }, discount: { centAmount: 0 } },
  );

export const getVariantWithLowestPrice = (variants) =>
  variants.reduce((prev, curr) => {
    if (curr.price.centAmount < prev.price.centAmount) {
      return curr;
    }
    return prev;
  }, variants[0]);

export const getProductUrlFromProductVariant = (variant) =>
  variant?.attributes?.find((attr) => attr.name === 'productUrl')?.value ?? '';
