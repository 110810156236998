import drupalPubSubService from '../../../services/drupalPubSub/drupalPubSub';

const SUBSCRIBED_CART_FULFILLED_EVENTS = [
  'cart/addLineItems/fulfilled',
  'cart/changeLineItemQuantity/fulfilled',
  'cart/removeLineItem/fulfilled',
  'cart/getActiveCart/fulfilled',
  'order/getOrder/fulfilled',
];

const cartChangesToDrupal = (store) => (next) => async (action) => {
  const result = next(action);

  if (SUBSCRIBED_CART_FULFILLED_EVENTS.includes(action.type)) {
    const { cart } = store.getState();
    drupalPubSubService.publish({
      subscription: 'onCartUpdate',
      parameters: {
        lineItems: cart.lineItems.map((lineItem) => ({
          id: lineItem.id,
          quantity: lineItem.quantity,
        })),
      },
    });
  }

  return result;
};

export default cartChangesToDrupal;
