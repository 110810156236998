import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import navigationService from '../../services/navigation-api';

export const getNavItems = createAsyncThunk(
  'navigation/getNavItems',
  async () => navigationService.getNavigationItems(),
);

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    navItems: [],
    status: 'idle',
    collections: {},
    currentBynderImage: {},
  },
  reducers: {},
  extraReducers: {
    [getNavItems.pending]: (state) => ({
      ...state,
      status: 'loading',
    }),
    [getNavItems.fulfilled]: (state, action) => ({
      ...state,
      navItems: action.payload.navItems,
      collections: action.payload.collections,
      status: 'succeeded',
    }),
    [getNavItems.rejected]: (state) => ({
      ...state,
      status: 'failed',
    }),
  },
});

export default navigationSlice.reducer;
