import { createSlice, createAction } from '@reduxjs/toolkit';

export const setSelectedVariantsForBundleItemProducts = createAction(
  'bundlePdp/setSelectedVariantsForBundleItemProducts',
);

export const setSelectedAttributesValues = createAction(
  'bundlePdp/setSelectedAttributeValues',
);

export const setPriceTotal = createAction(
  'bundlePdp/setPriceTotal',
);

export const setDiscountPriceTotal = createAction(
  'bundlePdp/setDiscountPriceTotal',
);

export const bundlePdpSlice = createSlice({
  name: 'bundlePdp',
  initialState: {
    selectedVariantsForBundleItemProducts: {},
    selectedAttributesValues: {},
    priceTotal: {},
    discountPriceTotal: {},
  },
  reducers: {
    setSelectedVariantsForBundleItemProducts: (state, data) => ({
      ...state,
      selectedVariantsForBundleItemProducts:
        data.payload.selectedVariantsForBundleItemProducts,
    }),
    setSelectedAttributeValues: (state, data) => ({
      ...state,
      selectedAttributesValues: {
        ...state.selectedAttributesValues,
        ...data.payload,
      }
    }),
    setPriceTotal: (state, data) => ({
      ...state,
      priceTotal: data.payload,
    }),
    setDiscountPriceTotal: (state, data) => ({
      ...state,
      discountPriceTotal: data.payload,
    }),
  },
  extraReducers: {},
});

export default bundlePdpSlice.reducer;
