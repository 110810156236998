import {
  TEALIUM_ADDED_LOCATION_COOKIE
  } from '../../utils/common/constants';

  const setCookieForTealiumLineItemAddedLocation = (lineItemData) => {
    let addedLocation = JSON.parse(localStorage.getItem(TEALIUM_ADDED_LOCATION_COOKIE));
    const lineItemLocation = addedLocation?.find(
      itemLocation => itemLocation.lineItemId === lineItemData.lineItemId
    );

    if (lineItemData?.itemAddedLocation === null) {
      if (lineItemLocation?.item_list_id) {
        const updatedAddedLocation = addedLocation
        ?.filter(itemLocation => itemLocation.lineItemId !== lineItemData.lineItemId);
        addedLocation = updatedAddedLocation;
      }
    } else if(addedLocation?.length) {
        
        if (!lineItemLocation) {
          addedLocation = [
            ...addedLocation,
            {
              lineItemId: lineItemData.lineItemId,
              item_list_id: lineItemData.itemsListId,
              item_added_location: document.location.pathname,
            }
          ]
        }
      } else {
        addedLocation = [{
          lineItemId: lineItemData.lineItemId,
          item_list_id: lineItemData.itemsListId,
          item_added_location: document.location.pathname,
        }]
      }

    localStorage.setItem(TEALIUM_ADDED_LOCATION_COOKIE, JSON.stringify(addedLocation));
  
  }
  
  const tealiumStorageService = { setCookieForTealiumLineItemAddedLocation };

  export default tealiumStorageService;