import request from '../../client';

const getProductsSpecsAndMaterials = async (productKeys) => {
  try {
    let queryParams =
      'filter[product_key][condition][path]=field_product_key&filter[product_key][condition][operator]=IN&';
    queryParams += productKeys
      .map(
        (productKey) => `filter[product_key][condition][value][]=${productKey}`,
      )
      .join('&');
    queryParams += '&include=field_specs_and_materials.field_p_items';

    return await request({
      host: window.drupalSettings?.pur_api?.drupalBase,
      uri: `commerce_product?${queryParams}`,
      options: {
        method: 'GET',
      },
    });
  } catch (error) {
    return {};
  }
};

export default { getProductsSpecsAndMaterials };
