import request from '../../client';

const login = async (email, anonymousId, anonymousCartSignInMode) => {
  try {
    // TO-DO: Implement Amplify to use Auth.currentSession
    // const cognitoSession = ;
    // Merge Cart Call

    const response = await request({
      uri: 'customers/login',
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          anonymousId,
        },
        body: JSON.stringify({
          email,
          anonymousCartSignInMode,
        }),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getCTCustomer = async (customerId) => {
  const response = await request({
    uri: 'customers/',
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        customerId,
      },
    },
  });
  return response;
};

const createCustomer = async (customerInfo, anonymousId) => {
  try {
    const {
      email,
      password,
      firstName,
      lastName,
      addresses,
    } = customerInfo;

    const response = await request({
      uri: 'customers/',
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          anonymousId,
        },
        body: JSON.stringify({
          email,
          password,
          firstName,
          lastName,
          addresses,
        }),
      },
    });
    return response;
  } catch (error) {
    return error.message;
  }
};

const addCustomerAddress = async (customerId, address) => {
  try {
    const response = await request({
      uri: 'customers/addresses',
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          customerId,
        },
        body: JSON.stringify(address),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const updateCustomerAddress = async (customerId, addressId, address) => {
  try {
    const response = await request({
      uri: `customers/addresses/${addressId}`,
      options: {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          customerId,
        },
        body: JSON.stringify(address),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const AccountsService = {
  login,
  getCTCustomer,
  createCustomer,
  addCustomerAddress,
  updateCustomerAddress,
};

export default AccountsService;
