/* eslint-disable indent */
import messages from './messages';
import {
  ITEMS_NOT_DELIVERABLE_ERROR,
} from '../blocks/Checkout/SteppedCheckout/constants';
import {
  WARRANTY_CUSTOM_LINE_ITEM_TYPE,
  TITLE_ATTRIBUTE_NAME,
  CHARGE_AFTER_MINIMUM_PRODUCT_DISCOUNTED_PRICE_CENTAMOUNT,
  AFFIRM_MINIMUM_VALUE_CENTAMOUNT,
} from './common/constants';
import {
  TEALIUM_ATTRIBUTES_INCLUDED_LIST,
  TEALIUM_SIZE_ATTRIBUTES,
  COLOR_ATTRIBUTES,
} from '../blocks/pdp/constants';

export const stringToCentAmount = ({
  value,
  fractionDigits = 2,
  currencyCode = 'USD',
}) => ({
  centAmount: value ? +value * 10 ** fractionDigits : 0,
  fractionDigits,
  currencyCode,
});

export const priceToString = ({
  price,
  formatNumber,
  formatMessage,
  forceDigit,
  maximumFractionDigits,
  minimumFractionDigits = undefined,
}) => {
  if (!price.centAmount && !forceDigit) {
    return formatMessage(messages.free);
  }
  return formatNumber(price.centAmount / 10 ** price.fractionDigits, {
    style: 'currency',
    currency: price.currencyCode,
    maximumFractionDigits: maximumFractionDigits ?? price.fractionDigits,
    minimumFractionDigits:
      minimumFractionDigits ?? maximumFractionDigits === 0 ? 0 : undefined,
  });
};

export const parseGiftCardBalance = ({
  balanceAmount,
  formatNumber,
  formatMessage,
}) => {
  if (balanceAmount) {
    return priceToString({
      price: balanceAmount,
      formatNumber,
      formatMessage,
      forceDigit: true,
    });
  }
  return '';
};

export const calculateTotalDiscount = ({ quantity, custom }) =>
  JSON.parse(custom.fields.discounts_json).reduce(
    (totalLine, discount) => totalLine + discount.cent_amount,
    0,
  ) * quantity;

export const calculateTotalPriceWithoutDiscount = ({ price, custom }) => {
  const discountJson = custom?.fields?.discounts_json;
  return price.value?.centAmount +
    JSON.parse(discountJson)?.reduce(
    (totalLine, discount) => totalLine + discount.cent_amount,
    0,
  );
};

export const getTotalItems = (lineItems) =>
  lineItems.reduce((items, lineItem) => items + lineItem.quantity, 0);

export const calculatetShippingTotal = ({ customLineItems = [] }) =>
  customLineItems
    .filter(
      (shippingItem) => !!shippingItem.custom?.fields?.netsuite_shipping_name,
    )
    .reduce(
      (total, customLineItem) => total + customLineItem.totalPrice.centAmount,
      0,
    );

export const calculateWarrantyPrice = ({ customLineItems = [] }) =>
  customLineItems
    ?.filter(
      (customLineItem) =>
        customLineItem.custom?.fields?.type === WARRANTY_CUSTOM_LINE_ITEM_TYPE,
    )
    ?.reduce(
      (totalWarrantPrice, customLineItem) =>
        totalWarrantPrice + customLineItem.totalPrice.centAmount,
      0,
    );

export const calculateSubtotal = ({ lineItems }) =>
  lineItems.reduce((subtotal, lineItem) => {
    const totalDiscounted = lineItem.totalPrice.centAmount;
    const discounts = JSON.parse(
      lineItem.custom?.fields?.discounts_json || '[]',
    );

    const totalDiscount = discounts.reduce(
      (acc, curr) => acc + curr.cent_amount * lineItem.quantity,
      0,
    );

    return subtotal + totalDiscounted + totalDiscount;
  }, 0);

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const DEFAULT_RETRIES = 3;
const DEFAULT_DELAY_MILLISECONDS = 1000;

export const retryFn = async ({
  fn,
  args,
  retriesLeft = DEFAULT_RETRIES,
  delayTime = DEFAULT_DELAY_MILLISECONDS,
}) => {
  try {
    return await fn(args);
  } catch (error) {
    if (retriesLeft > 0) {
      await delay(delayTime);
      return retryFn({
        fn,
        args,
        retriesLeft: retriesLeft - 1,
        delayTime,
      });
    }
    throw error;
  }
};

export const isCreditDiscount = (discount) => !!discount?.credit?.id;
export const isCreditDiscountApplied = (discount) =>
  isCreditDiscount(discount) && discount.credit.used_cents > 0;

export const calculateTotalPromo = ({ lineItems }) =>
  lineItems
    .filter((lineItem) => lineItem.custom?.fields?.discounts_json)
    .flatMap((lineItem) => ({
      quantity: lineItem.quantity,
      discounts: JSON.parse(lineItem.custom?.fields?.discounts_json)
        .filter(
          (discount) =>
            discount.method === 'coupon' && !isCreditDiscount(discount),
        )
        .reduce((acc, curr) => acc + curr.cent_amount, 0),
    }))
    .reduce(
      (totalDiscAmount, lineItem) =>
        totalDiscAmount + lineItem.discounts * lineItem.quantity,
      0,
    );

export const calculateTotalDiscounts = ({ lineItems }) =>
  lineItems
    .filter((lineItem) => lineItem.custom?.fields?.discounts_json)
    .flatMap((lineItem) => ({
      quantity: lineItem.quantity,
      discounts: JSON.parse(lineItem.custom?.fields?.discounts_json)
        .filter(
          (discount) =>
            discount.method !== 'coupon' && !isCreditDiscount(discount),
        )
        .reduce((acc, curr) => acc + curr.cent_amount, 0),
    }))
    .reduce(
      (totalDiscAmount, lineItem) =>
        totalDiscAmount + lineItem.discounts * lineItem.quantity,
      0,
    );

export const calculateTotalPromoBedding = ({ lineItems = [] }) =>
  lineItems
    .filter((lineItem) => lineItem.custom?.fields?.discounts_json)
    .flatMap((lineItem) => ({
      quantity: lineItem.quantity,
      discounts: JSON.parse(lineItem.custom?.fields?.discounts_json)
        .filter(isCreditDiscount)
        .reduce((acc, curr) => acc + curr.credit.used_cents, 0),
    }))
    .reduce(
      (totalDiscAmount, lineItem) =>
        totalDiscAmount + lineItem.discounts * lineItem.quantity,
      0,
    );

export const calculateTotalPromoBeddingRemaining = ({ lineItems = [] }) => {
  const lineItemsWithBeddingPromo = lineItems.filter((li) =>
    JSON.parse(li.custom?.fields?.discounts_json).some(isCreditDiscount),
  );

  if (!lineItemsWithBeddingPromo.length) {
    return 0;
  }

  const totalCredit = lineItemsWithBeddingPromo
    .flatMap((lineItem) =>
      JSON.parse(lineItem.custom?.fields?.discounts_json)
        .filter(isCreditDiscount)
        .map((discount) => discount.credit),
    )
    .reduce(
      (acc, credit) => {
        if (acc.creditList.includes(credit.id)) {
          return acc;
        }
        return {
          total: acc.total + credit.total_cents,
          creditList: [...acc.creditList, credit.id],
        };
      },

      { total: 0, creditList: [] },
    );

  return lineItemsWithBeddingPromo
    .filter((lineItem) => lineItem.custom?.fields?.discounts_json)
    .flatMap((lineItem) => ({
      quantity: lineItem.quantity,
      discounts: JSON.parse(lineItem.custom?.fields?.discounts_json)
        .filter(isCreditDiscount)
        .reduce(
          (acc, curr) => acc + curr.credit.used_cents * lineItem.quantity,
          0,
        ),
    }))
    .reduce(
      (totalDiscAmount, lineItem) => totalDiscAmount - lineItem.discounts,
      totalCredit?.total,
    );
};
export const calculateTotalGiftCards = ({ giftCards = [] }) =>
  giftCards?.reduce((acc, giftCard) => acc + giftCard.amount.centAmount, 0);

export const calculateTotalMinusGiftCards = ({
  taxedPrice,
  totalPrice,
  giftCards,
}) => {
  const totalGiftCards = calculateTotalGiftCards({ giftCards });
  if (taxedPrice) {
    return (
      taxedPrice.totalGross?.centAmount &&
      Math.max(taxedPrice.totalGross?.centAmount - totalGiftCards, 0)
    );
  }
  return (
    totalPrice?.centAmount &&
    Math.max(totalPrice?.centAmount - totalGiftCards, 0)
  );
};

export const getVariantsWithPercentDiscount = (variants, percentage) =>
  variants.map(({ price, discount, ...variant }) => {
    const absolutePrice = price.centAmount + (discount?.centAmount ?? 0);
    const absoluteDiscount =
      100 * Math.ceil((absolutePrice * percentage) / 10000);

    return {
      ...variant,
      price: {
        ...price,
        centAmount: absolutePrice - absoluteDiscount,
      },
      discount: {
        ...price,
        centAmount: absoluteDiscount,
      },
    };
  });

export const getDiscountPercent = (priceCentAmount, discountCentAmount) =>
  Math.floor(
    (discountCentAmount / (priceCentAmount + discountCentAmount)) * 100,
  ) || 0;

export const getHighestDiscountedVariant = (variants) =>
  variants?.reduce(
    (maxDiscounted, currVariant) => {
      const maxDiscountedPercent = getDiscountPercent(
        maxDiscounted.price.centAmount,
        maxDiscounted.discount?.centAmount,
      );

      const currVariantDiscountPercent = getDiscountPercent(
        currVariant.price.centAmount,
        currVariant.discount?.centAmount,
      );

      return currVariantDiscountPercent > maxDiscountedPercent
        ? currVariant
        : maxDiscounted;
    },
    { price: { centAmount: 0 }, discount: { centAmount: 0 } },
  );

export const isBillingAddressValid = (address) => {
  const {
 firstName, lastName, streetName, postalCode, city, country, state,
} =
    address || {};
  return Boolean(
    firstName &&
      lastName &&
      streetName &&
      postalCode &&
      city &&
      country &&
      state,
  );
};

export const isSameString = (str1, str2) =>
  (str1 ?? '').toLowerCase() === (str2 ?? '').toLowerCase();

export const isSameAddress = (addressA = {}, addressB = {}) => {
  const normalizeAddressA = {
    ...addressA,
    streetAddress: addressA.streetAddress || addressA.streetName,
  };
  const normalizeAddressB = {
    ...addressB,
    streetAddress: addressB.streetAddress || addressB.streetName,
  };

  return (
    isSameString(normalizeAddressA.streetAddress, normalizeAddressB.streetAddress) &&
    isSameString(normalizeAddressA.postalCode, normalizeAddressB.postalCode) &&
    isSameString(normalizeAddressA.city, normalizeAddressB.city) &&
    isSameString(normalizeAddressA.additionalStreetInfo,
      normalizeAddressB.additionalStreetInfo) &&
    isSameString(normalizeAddressA.country, normalizeAddressB.country) &&
    isSameString(normalizeAddressA.state, normalizeAddressB.state)
  );
};

export const isSameAddressWithName = (addressA = {}, addressB = {}) =>
  isSameAddress(addressA, addressB) &&
  addressA.firstName === addressB.firstName &&
  addressA.lastName === addressB.lastName;

export const isSameFullAddress = (addressA = {}, addressB = {}) =>
  isSameAddressWithName(addressA, addressB) &&
  addressA.email === addressB.email &&
  addressA.phone === addressB.phone;

export const isDuplicateAddress = (addressA = {}, addresses = []) =>
  !!addresses.find((addressB) => isSameAddress(addressA, addressB));

export const isShippingAddressValid = ({ email, phone, ...address } = {}) =>
  Boolean(email && phone && isBillingAddressValid(address));

export const isIOSDevice = () =>
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export const getLastURIComponent = () => {
  const components = window.location.href.split('/');
  const lastURIComponent =
    components[components.length - 1] === ''
      ? components[components.length - 2]
      : components[components.length - 1];
  return lastURIComponent.split('?').shift();
};

export const scrollToError = (block) => {
  const errorBlock = document.getElementById(`error-message-${block}`);
  const offsetY =
    errorBlock?.getBoundingClientRect().top + window.pageYOffset - 20;
  if (offsetY) {
    window.scrollTo({ top: offsetY, behavior: 'smooth' });
  }
};

export const focusAndScrollTo = (idSelector) => {
  const htmlElement = document.getElementById(idSelector);
  const offsetY =
    htmlElement?.getBoundingClientRect().top + window.pageYOffset - 40;
  htmlElement?.focus();
  if (offsetY) {
    window.scrollTo({ top: offsetY, behavior: 'smooth' });
  }
};

export const focusAndScrollToByName = (nameSelector) => {
  const htmlElement = document.getElementsByName(nameSelector)?.[0];
  if (htmlElement?.id) {
    focusAndScrollTo(htmlElement.id);
  }
};

export const scrollToElement = (idSelector) => {
  const htmlElement = document.getElementById(idSelector);
  if (htmlElement) {
    const offsetY =
      htmlElement?.getBoundingClientRect().top + window.pageYOffset - 40;
    if (offsetY) {
      window.scrollTo({ top: offsetY, behavior: 'smooth' });
    }
  }
};

export const scrollToElementDelay = (idSelector, delayTime) => {
    setTimeout(() => {
      scrollToElement(idSelector);
    }, delayTime);
};

const COUNTRY_MAP = {
  US: 'USA',
};

export const mapCountry = (countryCode) => {
  const mapped = COUNTRY_MAP[countryCode];

  return mapped || countryCode;
};

export const formatPhone = (phone) => {
  const value = phone.replace(/\D+/g, '');
  let formattedValue = '';

  if (value.length > 3 && value.length < 8) {
    formattedValue = value.replace(/(\d{3})(\d)/, '$1-$2');
  } else if (value.length >= 8 && value.length < 11) {
    formattedValue = value.replace(/(\d{3})(\d{3})(\d)/, '($1) $2-$3');
  } else if (value.length === 11) {
    formattedValue = value.replace(/(\d{1})?(\d{3})(\d{3})(\d)/, '($2) $3-$4');
  } else if (value.length === 12) {
    formattedValue = value.replace(/(\d{2})?(\d{3})(\d{3})(\d)/, '($2) $3-$4');
  } else {
    formattedValue = value;
  }
  return formattedValue;
};

export const formatGiftCardCode = (giftCardCode) => {
  const formattedValue = giftCardCode.replace(
    /(.*)(.{4})(.{4})(.{4})(.{4})/,
    '$1-$2-$3-$4-$5',
  );

  return formattedValue;
};

const deliveryMethodsErrorCodes = [ITEMS_NOT_DELIVERABLE_ERROR];

export const isDeliveryMethodError = (errorCode) =>
  deliveryMethodsErrorCodes.includes(errorCode);

export const getDrupalSettings = () =>
  // eslint-disable-next-line no-undef
  drupalSettings;

export const getBundlePdpAovItemsToRender = (
  paramItems,
  paramTargetProductSku,
) => {
  if (paramItems?.length > 0) {
    return paramItems.filter((item) =>
      item.options.every((option) =>
        option.variants.every(
          (variant) => variant.sku !== paramTargetProductSku,
        ),
      ),
    );
  }
  return [];
};

export const getBundlePdpAovTargetProductVariant = (
  paramItems,
  paramTargetProductSku,
) => {
  if (Array.isArray(paramItems)) {
    return paramItems
      .flatMap((item) => item.options)
      .flatMap((option) => option.variants)
      .find((variant) => variant.sku === paramTargetProductSku);
  }
  return undefined;
};

export const getCheapestVariant = (paramVariants) =>
  paramVariants?.reduce((lowestPriceVariant, variant) =>
    (variant.price?.centAmount < lowestPriceVariant.price?.centAmount
      ? variant
      : lowestPriceVariant),
  );

export const getTargetProductWithAllVariantsBySKU = (
  paramItems,
  paramTargetProductSKU,
) => {
  let targetProduct;
  paramItems
    .flatMap((item) => item.options)
    .forEach((option) => {
      option.variants.forEach((variant) => {
        if (variant.sku === paramTargetProductSKU) {
          targetProduct = option;
        }
      });
    });
  return targetProduct;
};

export const removeUScountryCode = (_phoneNumber) => {
  if (_phoneNumber.charAt(0) === '+') {
    return _phoneNumber.replace(/[^a-zA-Z0-9+]/g, '').substr(2);
  }
  return _phoneNumber;
};

export const getProductAttributes = (foundItem) => {
  const parentCategory =  foundItem?.category?.[1]?.name || null;
  const subCategory = foundItem?.category?.[2]?.name || null;
  
  const selectedVariantSize = foundItem.variant.attributes.filter(
    (attr) => (TEALIUM_SIZE_ATTRIBUTES.includes(attr.name))
  );
  const sizeAttribute = selectedVariantSize.find(attribute => /size/.test(attribute.name));
  let variantSize = sizeAttribute?.value?.label || selectedVariantSize?.[0]?.value;

  const variantTitle = foundItem.variant.attributes.filter(
    (attr) => attr.name === TITLE_ATTRIBUTE_NAME
  )[0]?.value;

  let variantColor = foundItem.variant.attributes.filter(
    (attr) => COLOR_ATTRIBUTES.includes(attr.name)
  )[0]?.value.label;

  const productVariantExtraAttributesArray =
    foundItem.variant.attributes.filter(
      (attr) => TEALIUM_ATTRIBUTES_INCLUDED_LIST.includes(attr.name),
    );
  const productVariantExtraAttributes =productVariantExtraAttributesArray?.[0]?.value?.label
    || null;

  if (variantTitle && !variantColor && variantTitle !== variantSize) {
    const escapedVariantSize = variantSize.replace(/[-\\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regexToReplaceSize = new RegExp(`${escapedVariantSize}\\s*\\|\\s*(.*)`, 'i');
    const color = variantTitle?.match(regexToReplaceSize)?.[1];
    variantColor = color !== productVariantExtraAttributes ? color : null;
  }

  variantSize = variantSize.replace(variantColor, '', variantSize);

  return {
    parentCategory,
    subCategory,
    variantColor,
    variantSize,
    productVariantExtraAttributes
  }
};

export const findWidgetStatus = ({ featureToggle, total }) => {
  const financingWidgetsAndPaymentMethodsABTestEnabled =
    featureToggle?.includes('chargeafter');

  const totalIsLessThanMinimumForFinance =
    total?.centAmount <
    CHARGE_AFTER_MINIMUM_PRODUCT_DISCOUNTED_PRICE_CENTAMOUNT;

  const totalIsHigherThanMinimumForAffirm =
    total?.centAmount > AFFIRM_MINIMUM_VALUE_CENTAMOUNT;
  
  const showAffirmWidget =
    (!financingWidgetsAndPaymentMethodsABTestEnabled &&
      totalIsHigherThanMinimumForAffirm) ||
    (financingWidgetsAndPaymentMethodsABTestEnabled &&
      totalIsLessThanMinimumForFinance);

  const showChargeAfterWidget =
    financingWidgetsAndPaymentMethodsABTestEnabled &&
    !totalIsLessThanMinimumForFinance;
  return {
    showChargeAfterWidget,
    showAffirmWidget,
  };
};
