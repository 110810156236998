import {
  PRODUCT_RELATION_URL,
  COLLECTIONS_TYPE,
  PRODUCTS_TYPE,
  BYNDER_TYPE,
  FILE_TYPE,
  BADGE_TYPE,
  LINK_TYPE,
} from '../../utils/common/constants';

const navigationUrl = process.env.NAVIGATION_API_BASE_URL;

/* eslint-disable camelcase */
const getSitewideBanner = async () => {
  try {
    const response = await fetch(
      `${navigationUrl}/block_content/sitewide_banner?include=field_p_info_items.field_p_cta`,
    );
    if (response.ok) {
      const result = await response.json();
      if (result.included.length > 0) {
        const infoItems = result.included
          .filter((item) => item.type === 'paragraph--info_item')
          .map((item) => {
            // Data structure for the slide itself.
            const data = {
              description: null,
              id: item.id,
              cta: {
                target_id: null,
              },
              countdown: {
                endDate: null,
                prefix: null,
                suffix: null,
              },
            };
            const {
              field_description,
              field_end_date,
              field_prefix,
              field_suffix,
            } = item.attributes;
            if (field_description) {
              data.description = field_description.value;
            }
            // Set parent ID.
            if (item.relationships.field_p_cta) {
              data.cta.target_id =
                item.relationships.field_p_cta.data.meta.drupal_internal__target_id;
            }
            // Countdown Timer.
            data.countdown.endDate = field_end_date;
            data.countdown.prefix = field_prefix;
            data.countdown.suffix = field_suffix;
            return data;
          });
        // The CTAs are child entities to the info item. Need a second pass since the entites are flattened
        // in the included array. CTAs can be link_default OR promo_modal.
        return infoItems.map((infoItem) => {
          const cta = result.included.find(
            (include) =>
              infoItem.cta.target_id === include.attributes.drupal_internal__id,
          );
          return {
            ...infoItem,
            cta: {
              ...infoItem.cta,
              type:
                cta?.type === 'paragraph--link_global_promotion_modal' ||
                cta?.type === 'paragraph--link_default'
                  ? cta.type
                  : infoItem.cta.type,
              contentUrl:
                cta?.type === 'paragraph--link_global_promotion_modal'
                  ? // eslint-disable-next-line max-len
                    `/api/decoupled/global_promotion_modal/${cta.relationships.field_global_promotion_modal.data.meta.drupal_internal__target_id}`
                  : infoItem.cta.content_url,
              link:
                cta?.type === 'paragraph--link_default'
                  ? cta.attributes.field_link
                  : infoItem.cta.link,
            },
          };
        });
      }
      return [];
    }
    throw new Error('Unable to fetch sitewide banner', response.status);
  } catch (err) {
    return [];
  }
};

/* * Bynder Images * */
const getBynderImage = (files, fileId) =>
  files.find((file) => file.id === fileId);

const getBynderMedia = (images, mediaId) =>
  images.find((image) => image.id === mediaId);

const getCollections = (collection, products, links) =>
  collection.collectionItems.map((item) => ({
    collection: {
      ...item,
      attributes: {
        ...item.attributes,
        field_links: links.find(
          (link) =>
            link.attributes.parent_id ===
            item.attributes.drupal_internal__id.toString(),
        ),
      },
    },
    products: products.filter(
      (productItem) =>
        item.attributes.drupal_internal__id.toString() ===
        productItem.product.attributes.parent_id,
    ),
  }));

/* * Collection Category ID * */
const getTargetId = (collections, collectionId) =>
  collections.find(
    (collection) => collection.attributes.parent_id === collectionId,
  ).attributes.drupal_internal__id;

const getCollectionItems = (collections, collectionId) =>
  collections.filter(
    (collection) => collection.attributes.parent_id === collectionId,
  );

/* * Badges * */
const getBadges = (badges, badgeId) =>
  badges.find((badge) => badge.id === badgeId);

const getNavigationItems = async () => {
  try {
    const response = await fetch(
      `${navigationUrl}/menu_items/main-nav${PRODUCT_RELATION_URL}`,
    );
    if (response.ok) {
      const { data, included } = await response.json();
      const linkItems = included.filter((item) => item.type === LINK_TYPE);
      const collectionItems = included.filter(
        (item) => item.type === COLLECTIONS_TYPE,
      );
      const collectionParents = data
        .filter(
          (item) => item.relationships.field_product_section.data.length > 0,
        )
        .map((collection) => ({
          ...collection,
          collectionItems: getCollectionItems(
            collectionItems,
            collection.attributes.meta.entity_id,
          ),
          target_id: getTargetId(
            collectionItems,
            collection.attributes.meta.entity_id,
          ),
        }));

      const badges = included.filter((item) => item.type === BADGE_TYPE);
      const bynderfiles = included
        .filter((item) => item.type === FILE_TYPE)
        .map((file) => ({
          ...file,
          attributes: {
            ...file.attributes,
            uri: {
              ...file.attributes.uri,
              url: `${window.location.origin}${file.attributes.uri.url}`,
            },
          },
        }));
      const bynderImages = included
        .filter((item) => item.type === BYNDER_TYPE)
        .map((image) => ({
          ...image,
          file: getBynderImage(
            bynderfiles,
            image.relationships.thumbnail.data.id,
          ),
        }));
      const products = included
        .filter((item) => item.type === PRODUCTS_TYPE)
        .map((product) => ({
          product: { ...product },
          bynderMedia: product?.relationships?.field_media?.data?.id
            ? getBynderMedia(
                bynderImages,
                product.relationships.field_media.data.id,
              )
            : null,
          badges: product?.relationships.field_nav_item_badge?.data?.id
            ? getBadges(
                badges,
                product?.relationships.field_nav_item_badge?.data?.id,
              )
            : null,
        }));

      const collectionsObj = collectionParents.map((collection) => ({
        [collection.attributes.meta.entity_id]: getCollections(
          collection,
          products,
          linkItems,
        ),
      }));

      const collections = Object.assign({}, ...collectionsObj);
      const navItems = data.map((navLink) => ({
        ...navLink,
        bynderMedia: navLink.relationships?.field_media?.data?.id
          ? getBynderMedia(
              bynderImages,
              navLink.relationships.field_media.data.id,
            )
          : null,
      }));
      return { navItems, collections };
    }
    throw new Error('Unable to fetch top level nav links', response.status);
  } catch (err) {
    return [];
  }
};

const NavigationService = {
  getSitewideBanner,
  getNavigationItems,
  getBynderMedia,
};

export default NavigationService;
