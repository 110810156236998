import request from '../../client';

const getCatalogPromoPrice = async ({ fixed, options }) => {
  const response = await fetch(
    `${process.env.BASE_API_URL}/catalog/catalog-promo-price`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fixed, options }),
    },
  );
  if (response.ok) {
    return response.json();
  }
  throw new Error('Something wrong happened', response.status);
};

const getProductByKey = async (productKey) => {
  try {
    return await request({
      uri: `catalog/product/${productKey}`,
      options: {
        method: 'GET',
      },
    });
  } catch (err) {
    return false;
  }
};

const catalogService = {
  getCatalogPromoPrice,
  getProductByKey,
};

export default catalogService;
