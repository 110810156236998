import request from '../../client';

const getActiveCart = async () => {
  try {
    return await request({
      uri: 'carts/active-cart',
      options: {
        method: 'GET',
        headers: {
          'Cache-Control': 'private, max-age=0, no-cache, no-store',
          Expires: 0,
        },
      },
    });
  } catch (error) {
    return false;
  }
};

const getTaxEstimation = (zipCode) =>
  request({
    uri: `carts/active-cart/tax-estimation?zipCode=${zipCode}`,
    options: {
      method: 'GET',
    },
  });

const addLineItems = (lineItems) =>
  request({
    uri: 'carts/line-items',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        lineItems.map(({ sku, quantity, warrantyPlanId }) => ({
          sku,
          quantity,
          warrantyPlanId,
        })),
      ),
    },
  });

const removeLineItem = async (lineItemId) => {
  try {
    return await request({
      uri: `carts/line-items/${lineItemId}`,
      options: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    });
  } catch (error) {
    return false;
  }
};

const changeLineItemQuantity = async (lineItemId, quantity) => {
  try {
    return await request({
      uri: `carts/line-items/${lineItemId}/quantity`,
      options: {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ quantity }),
      },
    });
  } catch (error) {
    return false;
  }
};

const setShippingAddress = ({ shippingAddress }) =>
  request({
    uri: 'carts/shipping-address',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(shippingAddress),
    },
  });

const setBillingAddress = ({ billingAddress }) =>
  request({
    uri: 'carts/billing-address',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...billingAddress,
        streetAddress:
          billingAddress.streetAddress || billingAddress.streetName,
      }),
    },
  });

const removeBillingAddress = () =>
  request({
    uri: 'carts/billing-address',
    options: {
      method: 'DELETE',
    },
  });

const updateVariantLineItem = async (lineItemId, sku) => {
  try {
    return await request({
      uri: `carts/line-items/${lineItemId}/variant`,
      options: {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sku }),
      },
    });
  } catch (error) {
    return false;
  }
};

const getVariantOptions = async (productId) => {
  try {
    return await request({
      uri: `carts/product/${productId}/variants`,
      options: {
        method: 'GET',
      },
    });
  } catch (error) {
    return false;
  }
};

const validateAddress = async (address) => {
  try {
    return await request({
      uri: 'carts/address/validate',
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(address),
      },
    });
  } catch (e) {
    return false;
  }
};

const getShippingMethods = ({
  overwritePaidInHomeSetupShipping = false,
  customInHomeSetupPricing = false,
}) =>
  request({
    uri: 'carts/shipping-methods',
    options: {
      method: 'GET',
      headers: {
        ...(overwritePaidInHomeSetupShipping
          ? { 'x-overwrite-paid-in-home-setup-shipping-ab-test': true }
          : {}),
        ...(customInHomeSetupPricing
          ? {
            'x-custom-in-home-setup-pricing-ab-test':
              customInHomeSetupPricing,
          }
          : {}),
      },
    },
  });

const getWarrantyPlans = () =>
  request({
    uri: 'carts/warranty-plans',
    options: {
      method: 'GET',
    },
  });

const setShippingMethods = ({
  selectedShippingMethods,
  overwritePaidInHomeSetupShipping = false,
  customInHomeSetupPricing = false,
}) =>
  request({
    uri: 'carts/shipping-methods',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(overwritePaidInHomeSetupShipping
          ? { 'x-overwrite-paid-in-home-setup-shipping-ab-test': true }
          : {}),
        ...(customInHomeSetupPricing
          ? {
            'x-custom-in-home-setup-pricing-ab-test':
              customInHomeSetupPricing,
          }
          : {}),
      },
      body: JSON.stringify(selectedShippingMethods),
    },
  });

const getCartAOVs = async () => {
  try {
    return await request({
      uri: 'carts/aovs',
      options: {
        method: 'GET',
      },
    });
  } catch (e) {
    return false;
  }
};

const getPromoPrice = async ({ sku, quantity, ignoredSKU }) => {
  try {
    return await request({
      // eslint-disable-next-line max-len
      uri: `carts/${sku}/promo-price?ignoredSKU=${ignoredSKU}&quantity=${quantity}`,
      options: {
        method: 'GET',
      },
    });
  } catch (e) {
    return false;
  }
};

const addPromoCode = (promoCode) =>
  request({
    uri: 'carts/code',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code: promoCode }),
    },
  });

const removePromoCode = (promoCode) =>
  request({
    uri: `carts/code/${promoCode}`,
    options: {
      method: 'DELETE',
    },
  });

const addGiftCard = ({ giftCardNumber, securityCode }) =>
  request({
    uri: 'carts/gift-card',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ giftCardNumber, securityCode }),
    },
  });

const removeGiftCard = ({ giftCardCode }) =>
  request({
    uri: `carts/gift-card/${giftCardCode}`,
    options: {
      method: 'DELETE',
    },
  });

const giftCardBalance = ({ giftCardNumber, securityCode }) =>
  request({
    uri: 'carts/gift-card-balance',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ giftCardNumber, securityCode }),
    },
  });

const removeGiftCardsFromCart = () =>
  request({
    uri: 'carts/gift-card',
    options: {
      method: 'DELETE',
    },
  });

const addWarrantyPlanToLineItem = ({ lineItemId, warrantyPlanId }) =>
  request({
    uri: `carts/line-items/${lineItemId}/warranty-plan`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ warrantyPlanId }),
    },
  });
const getAbandonedCartToasts = async () => {
  const { included } = await request({
    uri: `block_content/abandon_cart_messages?include=field_abandon_cart_message`,
    host: window.drupalSettings.pur_api.drupalBase,
    options: {
      method: 'GET',
    },
  });
  return included;
};

const getAvailableDiscountCodeForAbandonedCarts = async (queryParams) => {
  const response = await request({
    host: process.env.ABANDONED_CARTS_COUPON_CODES_LAMBDA_URL,
    uri: `active-coupons${queryParams ?? ''}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
  return JSON.parse(response?.response ?? {});
};

const getAvailableDiscountCodeForAbandonedCheckout = async (queryParams) => {
  const response = await request({
    host: process.env.ABANDONED_CARTS_COUPON_CODES_LAMBDA_URL,
    uri: `active-coupons${queryParams ?? ''}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
  return JSON.parse(response?.response ?? {});
};

const getProductPopularity = ({ productKey }) =>
  request({
    uri: `carts/product-popularity/${productKey}`,
    options: {
      method: 'GET',
    },
  });

const getGuaranteeItems = async (pdpNodesString) => {
  try {
    const stringFirstPart =
      'hHoZuU7e/jsonapi/node/product_display?filter[nid][group][conjunction]=OR';
    const stringSecondPart = '&include=field_guarantee_items,field_product';
    return await request({
      host: window.location.origin,
      uri: `${stringFirstPart}${pdpNodesString}${stringSecondPart}`,
      options: {
        method: 'GET',
      },
      authToken: false
    });
  } catch (error) {
    return error;
  }
};

const cartService = {
  getActiveCart,
  getTaxEstimation,
  getVariantOptions,
  addLineItems,
  removeLineItem,
  changeLineItemQuantity,
  updateVariantLineItem,
  setBillingAddress,
  removeBillingAddress,
  setShippingAddress,
  validateAddress,
  getShippingMethods,
  getCartAOVs,
  getPromoPrice,
  setShippingMethods,
  addPromoCode,
  removePromoCode,
  addGiftCard,
  removeGiftCard,
  giftCardBalance,
  removeGiftCardsFromCart,
  getWarrantyPlans,
  addWarrantyPlanToLineItem,
  getAbandonedCartToasts,
  getAvailableDiscountCodeForAbandonedCarts,
  getAvailableDiscountCodeForAbandonedCheckout,
  getProductPopularity,
  getGuaranteeItems,
};

export default cartService;
