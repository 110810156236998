const execute = async (callback) => {
  try {
    // eslint-disable-next-line no-undef
    await turnstile.remove();
    // eslint-disable-next-line no-undef
    const widgetId = await turnstile.render(
      `#${process.env.TURNSTILE_DOM_ELEMENT_ID}`,
      {
        sitekey: process.env.TURNSTILE_SITE_KEY,
        callback: (token) => {
          // eslint-disable-next-line no-console
          console.error(`Turnstile: Challenge Success ${token}`);
          callback();
        },
        'error-callback': (error) => {
          // eslint-disable-next-line no-console
          console.error(`Turnstile: Challenge Error ${error}`);

          // returns false so Turnstile continues with the processing and not marks the error as handled
          return false;
        },
        'unsupported-callback': () => {
          // eslint-disable-next-line no-console
          console.error('Turnstile: Unsupported Browser');
        },
        'before-interactive-callback': () => {
          // eslint-disable-next-line no-console
          console.error('Turnstile: Before Interactive Mode');
        },
        'after-interactive-callback': () => {
          // eslint-disable-next-line no-console
          console.error('Turnstile: After Interactive Mode');
        },
        appearance: 'interaction-only',
        theme: 'light',
        size: 'normal',
      },
    );
    // eslint-disable-next-line no-console
    console.error(`Turnstile: widgetId ${widgetId}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Turnstile: SDK error ${error}`);
  }
};

const getResponse = async () => {
  // eslint-disable-next-line no-undef
  const isExpired = turnstile.isExpired();
  if (isExpired) {
    // eslint-disable-next-line no-undef
    await turnstile.refresh();
  }
  // eslint-disable-next-line no-undef
  const response = await turnstile.getResponse();
  return response;
};

export default { execute, getResponse };
